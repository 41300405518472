<template>
  <div
    :class="[
      $style['bpm-radio'],
      { [$style[`bpm-radio--${size}`]]: size !== null }
    ]"
  >
    <label
      :class="[
        $style['bpm-radio__label'],
        { [$style['bpm-radio__label--disabled']]: disabled },
        { [$style['bpm-radio__label--bubble']]: bubble },
        { [$style['bpm-radio__label--pill']]: pill },
        { [$style['bpm-radio__label--pill-checked']]: pill && checked },
        { [$style['bpm-radio__label--is-checked']]: bubble && checked },
        { [$style[`bpm-radio__label--${size}`]]: size !== null && this.size === 'x-small' }
      ]"
      :for="id"
    >
      <input
        v-show="!bubble && !pill"
        :checked="checked"
        :class="$style['bpm-radio__input']"
        :disabled="disabled"
        :id="id"
        :name="name"
        :required="required"
        type="radio"
        :value="value"
        @change="handleChange"
      />
      <span v-if="label && label !== ''" :class="[$style['bpm-radio__label-text'], { [$style['bpm-radio__label-text--bubble']]: bubble }, { [$style['bpm-radio__label-text--pill']]: pill }]" v-html="label"></span>
      <slot v-else></slot>
    </label>
  </div>
</template>

<script>
import uid from '../../utils/uid'

export default {
  name: 'BpmRadio',
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    bubble: {
      type: Boolean,
      default: false
    },
    pill: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    modelValue: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null // x-small, small, large
    },
    value: {
      type: String,
      default: null
    }
  },
  computed: {
    checked () {
      return this.modelValue === this.value
    },
    id () {
      return uid()
    }
  },
  methods: {
    handleChange () {
      this.$emit('change', this.value)
    }
  }
}
</script>

<style lang="scss" module src="./styles/BpmRadio.module.scss">
</style>
