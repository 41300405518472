<template>
  <!-- SLIDES -->
  <SectionComponent is-wider>
    <ContainerComponent is-wider>
      <SectionHeaderComponent is-wider>Steps to apply for Credit Line</SectionHeaderComponent>
      <VueSlickCarousel
        v-bind="carouselSettings"
      >
        <div
          v-for="slide in slides"
          :key="slide.id"
        >
          <SectionPanelComponent :class="$style['web-mb-slides-section__panel']">
            <div :class="$style['web-mb-slides-section__row']">
              <div
              :class="[
                $style['web-mb-slides-section__col'],
                $style['web-mb-slides-section__img-wrap']
              ]"
              >
                <ImgComponent
                  :src="slide.img"
                  alt="Image"
                />
              </div>
              <div
                :class="[
                  $style['web-mb-slides-section__col'],
                  $style['web-mb-slides-section__content-wrap']
                ]"
              >
                <div :class="$style['web-mb-slides-section__content']">
                  <h2>{{ slide.title }}</h2>
                  <p>{{ slide.text }}</p>
                </div>
                <div :class="$style['web-mb-slides-section__nums']">
                  <div v-for="n in slides.length" :key="n" :class="[{ [$style['web-mb-slides-section__current-slide']]: n === slide.id }]">{{ n }}</div>
                </div>
              </div>
            </div>
          </SectionPanelComponent>
        </div>
      </VueSlickCarousel>
    </ContainerComponent>
  </SectionComponent>
</template>

<script>
import ContainerComponent from '@/components/ui/ContainerComponent.vue'
import SectionComponent from '@/components/ui/SectionComponent.vue'
import SectionPanelComponent from '@/components/ui/SectionPanelComponent.vue'
import SectionHeaderComponent from '@/components/ui/SectionHeaderComponent.vue'
import ImgComponent from '@/components/ui/ImgComponent.vue'

import VueSlickCarousel from 'vue-slick-carousel'

import slideImg1 from '@/assets/images/slides/slide-1-img.png'
import slideImg2 from '@/assets/images/slides/slide-2-img.png'
import slideImg3 from '@/assets/images/slides/slide-3-img.png'
import slideImg4 from '@/assets/images/slides/slide-4-img.png'
import slideImg5 from '@/assets/images/slides/slide-5-img.png'
import slideImg6 from '@/assets/images/slides/slide-6-img.png'

export default {
  name: 'SlidesSection',
  components: {
    ContainerComponent,
    SectionComponent,
    SectionPanelComponent,
    SectionHeaderComponent,
    ImgComponent,
    VueSlickCarousel
  },
  data: () => {
    return {
      // json
      slides: [
        {
          id: 1,
          title: 'Apply for the Credit Line',
          text: 'One form, for instant credit line limit approval',
          img: slideImg1
        },
        {
          id: 2,
          title: 'Complete your KYC verification',
          text: 'All we need is a selfie or have a quick video call with our executives',
          img: slideImg2
        },
        {
          id: 3,
          title: 'Digital signature',
          text: 'SSign the loan agreement digitally, with zero paper work',
          img: slideImg3
        },
        {
          id: 4,
          title: 'Start withdrawing!',
          text: 'Choose how much you want to withdraw and get it transferred directly to your account',
          img: slideImg4
        },
        {
          id: 5,
          title: 'Easy repayments, your way',
          text: 'Want to pay 0 interest? Repay the money within the Grace Period. Or, pay with easy EMIs of 5, 10 or 20 months',
          img: slideImg5
        },
        {
          id: 6,
          title: 'Get upgraded',
          text: 'We\'ll always get you more. Your credit limit can be auto-increased after you repay the borrowed amount!',
          img: slideImg6
        }
      ],
      carouselSettings: {
        'dots': true,
        'dotsClass': 'slick-dots custom-dot-class',
        'edgeFriction': 0.35,
        'infinite': false,
        'speed': 500,
        'slidesToShow': 1,
        'slidesToScroll': 1
      }
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-slides-section {
  &__panel {
    margin: 0 0.5rem;
  }

  &__row {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

  &__col {
    flex: 1;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
    justify-content: center;
  }

  &__content-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__current-slide {
    color: #B51ACE;
  }

  &__nums {
    display: flex;
    justify-content: center;
    gap: 1rem;
    color: #A0A0A0;
    padding-top: 4rem;
  }

  @media (min-width: $min-md) {
    &__row {
      flex-direction: row;
      // align-items: center;
      text-align: initial;
    }

    &__content {
      gap: 1.5rem;
    }

    &__nums {
      justify-content: start;
      padding-top: 0;
      gap: 2rem;
    }
  }
}
</style>
