var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{class:_vm.$style['web-mb-reach-page']},[_c('section',{class:_vm.$style['web-mb-reach-page__section']},[_c('PagePanelComponent',[_c('ContainerComponent',[_c('div',{class:_vm.$style['web-mb-reach-page__row']},[_c('div',{class:[
              _vm.$style['web-mb-reach-page__col'],
              _vm.$style['web-mb-reach-page__content-wrap']
            ]},_vm._l((_vm.content),function(item){return _c('p',{key:item.id},[_c('span',{class:_vm.$style['web-mb-reach-page__content-name']},[_vm._v(_vm._s(item.name))]),_c('span',{class:_vm.$style['web-mb-reach-page__content-value']},[_vm._v(_vm._s(item.value))])])}),0),_c('div',{class:[
              _vm.$style['web-mb-reach-page__col'],
              _vm.$style['web-mb-reach-page__img-wrap']
            ]},[_c('ImgComponent',{attrs:{"src":_vm.mainImg,"alt":"Reach Us"}})],1)])])],1)],1),_c('BannerSection')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }