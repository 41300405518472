<template>
  <!-- BANNER -->
  <SectionComponent>
    <ContainerComponent>
      <BannerPanelComponent>
        <div :class="$style['web-mb-banner-section__row']">
          <div
            :class="[
              $style['web-mb-banner-section__col'],
              $style['web-mb-banner-section__content']
            ]"
          >
            <div>
              <h2 :class="$style['web-mb-banner-section__header']">Download VIVA</h2>
              <p :class="$style['web-mb-banner-section__header-descr']">Your Personal Loan App</p>
              <p>Instantly withdraw money from your credit limit of up to ₹2,00,000! No paperwork, ever</p>
            </div>
            <div :class="$style['web-mb-banner-section__download-wrap']">
              <div :class="$style['web-mb-banner-section__qr-code-wrap']">
                <ImgComponent
                  :src="qrCodeImg"
                  alt="QR Code"
                />
              </div>
              <div :class="$style['web-mb-banner-section__download-links-wrap']">
                <ImgComponent
                  :src="googlePlayImg"
                  :imgStyle="{ width: 'auto', height: '42px' }"
                  alt="Google Play"
                />
                <ImgComponent
                  :src="appStoreImg"
                  :imgStyle="{ width: 'auto', height: '42px' }"
                  alt="App Store"
                />
              </div>
            </div>
          </div>
          <div
            :class="[
              $style['web-mb-banner-section__col'],
              $style['web-mb-banner-section__img-wrap']
            ]"
          >
            <ImgComponent
              :src="img"
              alt="Img"
            />
          </div>
        </div>
      </BannerPanelComponent>
    </ContainerComponent>
  </SectionComponent>
</template>

<script>
import ContainerComponent from '@/components/ui/ContainerComponent.vue'
import SectionComponent from '@/components/ui/SectionComponent.vue'
import BannerPanelComponent from '@/components/ui/BannerPanelComponent.vue'
import ImgComponent from '@/components/ui/ImgComponent.vue'

import img from '@/assets/images/banner/banner-img.png'
import qrCodeImg from '@/assets/images/qr-code-img.svg'
import googlePlayImg from '@/assets/images/banner/banner-google-play-img.svg'
import appStoreImg from '@/assets/images/banner/banner-app-store-img.svg'

export default {
  name: 'BannerSection',
  components: {
    ContainerComponent,
    SectionComponent,
    BannerPanelComponent,
    ImgComponent
  },
  data: () => {
    return {
      img,
      qrCodeImg,
      googlePlayImg,
      appStoreImg
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-banner-section {
  &__row {
    display: flex;
    flex-direction: column;
  }

  &__col {
    flex: 1;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
    margin: 1.5rem 1rem;
  }

  &__header {
    font-size: 2.222em;
    font-weight: 500;
  }

  &__header-descr {
    font-size: 0.75em;
    padding-bottom: 1.5rem;
    color: #E9ECF8;
  }

  &__qr-code-wrap {
    display: none;
  }

  &__download-wrap {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-around;
  }

  &__download-links-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  }

  &__img-wrap {
    display: flex;
    justify-content: center;
    padding: 1rem 1rem 0 1rem;
  }

  @media (min-width: $min-md) {
    &__row {
      flex-direction: row;
    }

    &__content {
      gap: 3rem;
      text-align: initial;
      margin: 4rem 0 4rem 2rem;
    }

    &__download-wrap {
      justify-content: start;
    }

    &__download-links-wrap {
      flex-direction: column;
      gap: 1.5rem;
    }
  }

  @media (min-width: $min-lg) {
    &__qr-code-wrap {
      display: block;
    }

    &__content {
      margin: 3.75rem 0 3.75rem 5.5rem;
    }
  }
}
</style>
