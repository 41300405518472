<template>
  <img
    v-if="src"
    :class="$style['web-mb-img']"
    :src="src"
    :alt="alt"
    :style="imgStyle"
  />
</template>

<script>
// данный компонент занимается оптимизацией изображения
export default {
  name: 'ImgComponent',
  props: {
    src: {
      type: String,
      default: null
    },
    alt: {
      type: String,
      default: 'Image'
    },
    imgStyle: {
      type: Object,
      default: null
    }
    // ? спец. параметры для контроля изображения
  }
}
</script>

<style lang="scss" module>
.web-mb-img {
  display: block;
  height: auto;
  max-width: 100%;
}
</style>
