<template>
  <main :class="$style['web-mb-home-page']">
    <!-- WELCOME -->
    <section :class="$style['web-mb-home-page__section']">
      <PagePanelComponent :class="$style['web-mb-home-page__panel']">
        <ContainerComponent>
          <div :class="$style['web-mb-home-page__row']">
            <div :class="$style['web-mb-home-page__col']">
              <h1>India’s 1st Personal Line of Credit</h1>

              <div :class="$style['web-mb-home-page__lists-wrap']">
                <ul :class="$style['web-mb-home-page__list']">
                  <li>Credit Limit up to ₹2,00,000</li>
                  <li>Revolving line of credit</li>
                  <li>0% interest for up to 51 days</li>
                </ul>
                <ul :class="$style['web-mb-home-page__list']">
                  <li>Instant online approval in 15 minutes</li>
                  <li>Direct bank transfer</li>
                  <li>Pay interest only on the amount withdrawn</li>
                </ul>
              </div>

              <div
                :class="[
                  $style['web-mb-home-page__row'],
                  $style['web-mb-home-page__download']
                ]"
              >
                <div :class="$style['web-mb-home-page__col']">
                  <h2>Download mobile app</h2>
                  <div :class="$style['web-mb-home-page__download-wrap']">
                    <div :class="$style['web-mb-home-page__qr-code-wrap']">
                      <ImgComponent
                        :src="qrCodeImg"
                        alt="QR Code"
                      />
                    </div>
                    <div :class="$style['web-mb-home-page__download-links-wrap']">
                      <ImgComponent
                        :src="appStoreImg"
                        alt="App Store"
                      />
                      <ImgComponent
                        :src="googlePlayImg"
                        alt="Google Play"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="[
                $style['web-mb-home-page__col'],
                $style['web-mb-home-page__img-wrap']
              ]"
            >
            </div>
          </div>
        </ContainerComponent>
      </PagePanelComponent>
    </section>

    <AdvantagesSection :class="$style['web-mb-home-page__advantages']" />

    <WebMobBank />

    <SlidesSection />

    <!-- <AboutSection /> -->

    <ReviewsSection />

    <BannerSection />

    <FaqSection />
  </main>
</template>

<script>
import PagePanelComponent from '@/components/ui/PagePanelComponent.vue'
import ContainerComponent from '@/components/ui/ContainerComponent.vue'
import ImgComponent from '@/components/ui/ImgComponent.vue'

import AdvantagesSection from '@/components/site/sections/AdvantagesSection.vue'
import WebMobBank from '@/components/app/WebMobBank.vue'
import SlidesSection from '@/components/site/sections/SlidesSection.vue'
// import AboutSection from '@/components/site/sections/AboutSection.vue'
import ReviewsSection from '@/components/site/sections/ReviewsSection.vue'
import BannerSection from '@/components/site/sections/BannerSection.vue'
import FaqSection from '@/components/site/sections/FaqSection.vue'

import qrCodeImg from '@/assets/images/home/qr-code-img.svg'
import googlePlayImg from '@/assets/images/home/google-play-img.svg'
import appStoreImg from '@/assets/images/home/app-store-img.svg'

export default {
  name: 'HomePage',
  components: {
    PagePanelComponent,
    ContainerComponent,
    ImgComponent,
    AdvantagesSection,
    WebMobBank,
    SlidesSection,
    // AboutSection,
    ReviewsSection,
    BannerSection,
    FaqSection
  },
  data: () => {
    return {
      qrCodeImg,
      googlePlayImg,
      appStoreImg
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-home-page {
  &__section {
    margin-bottom: 1.5rem;
  }

  &__panel {
    background-image: url(../../../assets/images/home/back-img-3.png);
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__advantages {
    margin-top: -2rem;
  }

  &__row {
    display: flex;
  }

  &__col {
    flex: 1;
  }

  &__lists-wrap {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 2rem;
  }

  &__list {
    list-style-position: inside;
  }

  &__list li {
    position: relative;
    padding-bottom: 1rem;
    padding-left: 1rem;
    list-style: none;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0.5rem;
      width: 0.5rem;
      height: 0.5rem;
      background-image: url(../../../assets/icons/list-item-icon.svg);
    }
  }

  &__img-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0.5rem;
  }

  &__download &__img-wrap {
    flex: 1;
  }

  &__download-wrap {
    padding-top: 1rem;
  }

  &__download-wrap,
  &__download-links-wrap {
    display: flex;
    gap: 1rem;
  }

  &__download-links-wrap {
    flex-direction: column;
  }

  &__qr-code-wrap {
    display: none;
  }

  &__img-wrap {
    flex: 0;
  }

  @media (min-width: $min-md) {
    &__panel {
      background-image: url(../../../assets/images/home/back-img-2.png);
    }

    &__lists-wrap {
      flex-direction: row;
      gap: 3.5rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }

    &__list li {
      padding-bottom: 1.5rem;
    }

    &__advantages {
      margin-top: -2.5rem;
    }
  }

  @media (min-width: $min-lg) {
    &__panel {
      background-image: url(../../../assets/images/home/back-img-1.png);
      background-position: initial;
    }

    &__download &__img-wrap {
      flex: 0;
    }

    &__qr-code-wrap {
      display: block;
    }

    &__img-wrap {
      flex: 1;
    }

    &__advantages {
      margin-top: -5rem;
    }
  }
}
</style>
