var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{class:_vm.$style['web-mb-header']},[_c('ContainerComponent',[_c('div',{class:_vm.$style['web-mb-header__panel']},[_c('div',{class:_vm.$style['web-mb-header__row']},[_c('a',{attrs:{"href":"/"}},[_c('ImgComponent',{attrs:{"src":_vm.logoImg,"alt":"Logo","imgStyle":{ width: '85px', height: 'auto' }}})],1),_c('nav',{class:[_vm.$style['web-mb-header__nav']]},_vm._l((_vm.routes),function(route){return _c('div',{key:route.name},[_c('a',{attrs:{"href":route.path}},[_vm._v(_vm._s(route.title))])])}),0),_c('div',{class:[
            _vm.$style['web-mb-header__mb-nav-icon'],
            { [_vm.$style['web-mb-header__mb-nav-icon--is-open']]: _vm.isOpen }
          ],on:{"click":_vm.toggleMenu}})]),_c('nav',{class:[
          _vm.$style['web-mb-header__row'],
          _vm.$style['web-mb-header__mb-nav'],
          { [_vm.$style['web-mb-header__mb-nav--is-open']]: _vm.isOpen }
        ]},_vm._l((_vm.routes),function(route){return _c('div',{key:route.name},[_c('a',{attrs:{"href":route.path}},[_vm._v(_vm._s(route.title))])])}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }