<template>
  <PanelComponent
    :class="$style['web-mb-occupation__panel']"
    :loading="isFetchingScreen"
  >
    <!-- ButtonArrowWidget -->
    <div :class="$style['web-mb-occupation__back-btn']">
      <BackButtonComponent @click="prevStep" />
    </div>

    <div :class="$style['web-mb-occupation']">
      <div
        :class="[
          $style['web-mb-occupation__col'],
          $style['web-mb-occupation__form']
        ]"
      >
        <div :class="$style['web-mb-occupation__col']">
          <h2
            :style="fields[screenName + '.block.2']?.inlineStyle"
            v-html="fields[screenName + '.block.2']?.properties?.text || 'Occupation'"
          ></h2>

          <div :class="$style['web-mb-occupation__select-wrap']">
            <bpm-select
              v-model="occupation"
              :options="occupationData"
              :placeholder="fields[screenName + '.input.dropDownList.3']?.properties?.placeholder"
              :label="fields[screenName + '.input.dropDownList.3']?.properties?.label"
              :state="!fields[screenName + '.input.dropDownList.3']?.properties?.errorIndicator ? null : false"
              :error="(fields[screenName + '.input.dropDownList.3']?.properties?.errorIndicator && fields[screenName + '.input.dropDownList.3']?.properties?.errorText) ? fields[screenName + '.input.dropDownList.3']?.properties?.errorText : ''"
              view="web"
            />
          </div>
        </div>
        <div :class="$style['web-mb-occupation__col']"></div>
      </div>

      <div :class="$style['web-mb-occupation__col']"></div>

      <div :class="$style['web-mb-occupation__col']">
        <ProgressBarComponent
          :text="fields[screenName + '.webProgressBar.1']?.properties?.text || 'Filling progress'"
          :value="fields[screenName + '.webProgressBar.1']?.properties?.progress || 80"
        >
          <!-- ButtonNextWidget -->
          <bpm-button
            fullwidth
            @click="nextStep"
          >Continue</bpm-button>
        </ProgressBarComponent>
      </div>
    </div>
  </PanelComponent>
</template>

<script>
import PanelComponent from '@/components/app/ui/PanelComponent.vue'
import ProgressBarComponent from '@/components/app/ui/ProgressBarComponent.vue'
import BackButtonComponent from '@/components/app/ui/BackButtonComponent.vue'

import currentStepMixin from '@/mixins/currentStepMixin'

export default {
  name: 'OccupationScreen',
  components: {
    PanelComponent,
    ProgressBarComponent,
    BackButtonComponent
  },
  mixins: [
    currentStepMixin
  ],
  data: () => {
    return {
      occupation: '',
      occupationLocalData: [
        {
          id: 1,
          label: 'Salaried',
          value: 'Salaried',
          disabled: false
        },
        {
          id: 2,
          label: 'Self-employed',
          value: 'Self-employed',
          disabled: false
        },
        {
          id: 3,
          label: 'Government Employee',
          value: 'Government Employee',
          disabled: false
        },
        {
          id: 4,
          label: 'Employment Student',
          value: 'Employment Student',
          disabled: false
        },
        {
          id: 5,
          label: 'Student',
          value: 'Student',
          disabled: false
        },
        {
          id: 6,
          label: 'Unemployed',
          value: 'Unemployed',
          disabled: false
        },
        {
          id: 7,
          label: 'Homemaker',
          value: 'Homemaker',
          disabled: false
        },
        {
          id: 8,
          label: 'Retired/Pensioner',
          value: 'Retired/Pensioner',
          disabled: false
        }
      ]
    }
  },
  computed: {
    currentNodeData () {
      return {
        [this.screenName + '.input.dropDownList.3']: this.occupation
      }
    },
    occupationData () {
      const list = this.getArrData(this.fields[this.screenName + '.input.dropDownList.3']?.listData, this.occupationLocalData)

      return list.map((item, index) => {
        return {
          id: index + 1,
          label: item,
          value: item,
          disabled: false
        }
      })
    }
  },
  watch: {
    currentNodeData: {
      handler () {
        this.nodeData = this.currentNodeData
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-occupation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  &__panel {
    display: flex;
    flex-direction: column;
  }

  &__back-btn {
    padding-bottom: 1rem;
  }

  &__select-wrap {
    padding-top: 1.5rem;
  }
}

@media (min-width: $min-md) {
  .web-mb-occupation {
    &__back-btn {
      padding-bottom: 1.5rem;
    }

    &__form {
      display: flex;
      flex-direction: row;
    }

    &__form &__col {
      flex: 1;
    }
  }
}

@media (min-width: $min-lg) {
  .web-mb-occupation {
    flex-direction: row;

    &__back-btn {
      padding-bottom: 2.5rem;
    }

    &__col {
      flex: 1;
    }

    &__form {
      flex-direction: column;
    }

    &__select-wrap {
      padding-top: 2rem;
    }
  }
}
</style>
