<template>
  <div :class="$style['web-mb-calculator']">
    <PanelComponent
      medium
      :class="[
        $style['web-mb-calculator__col'],
        { [$style['web-mb-calculator__panel']]: !isFetchingScreen }
      ]"
      :loading="isFetchingScreen"
    >
      <SectionHeaderComponent
        :class="$style['web-mb-calculator__header']"
        v-html="fields[screenName + '.block.1']?.properties?.text || 'Personal Loan EMI Calculator'"
      ></SectionHeaderComponent>

      <div :class="$style['web-mb-calculator__panel-row']">
        <p
          v-html="fields[screenName + '.block.2']?.properties?.text || 'Choose your credit limit'"
        ></p>
        <!-- .range.3 -->
        <bpm-range
          v-model="nodeData[screenName + '.input.range.3']"
          :min="100"
          :max="100000"
          currency="₹"
          view="web"
        />
      </div>

      <div :class="$style['web-mb-calculator__panel-row']">
        <p v-html="fields[screenName + '.block.4']?.properties?.text || 'Choose a loan term'"></p>

        <!-- .radiobutton.5 -->
        <div :class="$style['web-mb-calculator__radio-group']">
          <bpm-radio v-model="radioValue" value="5" label="5 months" name="months" pill />
          <bpm-radio v-model="radioValue" value="10" label="10 months" name="months" pill />
          <bpm-radio v-model="radioValue" value="20" label="20 months" name="months" pill />
        </div>
        <p
          :class="$style['web-mb-calculator__description']"
          v-html="fields[screenName + '.block.6']?.properties?.text || 'This loan term will be valid if you do not repay your debt before the end date of the Grace Period. You can always change this term later in the mobile app, before the end of your Grace Period'"
        ></p>
      </div>

      <div :class="$style['web-mb-calculator__panel-row']">
        <!-- action -->
        <bpm-button
          fullwidth
          @click="nextStep"
          v-html="fields[screenName + '.button.main.7']?.properties?.text || 'Apply'"
        ></bpm-button>
        <p
          :class="$style['web-mb-calculator__description']"
          v-html="fields[screenName + '.block.8']?.properties?.text || 'The calculations are relevant if you withdraw your entire credit limit'"
        ></p>
      </div>
    </PanelComponent>

    <div
      :class="[
        $style['web-mb-calculator__col'],
        $style['web-mb-calculator__panels']
      ]"
    >
      <PanelComponent
        small
        :loading="isFetchingScreen"
        :class="$style['web-mb-calculator__small-panel']"
      >
        <div>
          <h3 v-html="fields[screenName + '.block.9']?.properties?.text || 'Repayment within Grace Period'"></h3>
          <p
            :class="$style['web-mb-calculator__small-text']"
            v-html="fields[screenName + '.block.10']?.properties?.text || 'Settle the full amount, at zero interest — pay beforethe end of your Grace Period'"
          ></p>
        </div>
        <div>
          <p
            :class="$style['web-mb-calculator__data-name']"
            v-html="fields[screenName + '.block.11']?.properties?.text || 'Total payable:'"
          ></p>
          <p
            :class="$style['web-mb-calculator__data-value']"
            v-html="fields[screenName + '.block.12']?.properties?.text || ''"
          ></p>
          <p
            :class="$style['web-mb-calculator__data-description']"
            v-html="fields[screenName + '.block.13']?.properties?.text || ''"
          ></p>
        </div>
        <div :class="$style['web-mb-calculator__help-wrap']">
          <p
            :class="$style['web-mb-calculator__description']"
            v-html="fields[screenName + '.block.14']?.properties?.text || ''"
          ></p>
          <!-- .webTooltip.16 -->
          <TooltipComponent text="This is a one-time processing fee of ₹1,000 on your first withdrawal. All your subsequent withdrawals will be free">
            <!-- .webImage.15 -->
            <div :class="$style['web-mb-calculator__help-icon']"></div>
          </TooltipComponent>
        </div>
      </PanelComponent>
      <div :class="$style['web-mb-calculator__divider']">
        <template v-if="!isFetchingScreen"><div v-html="fields[screenName + '.block.17']?.properties?.text || ''"></div></template>
      </div>
      <PanelComponent
        small
        :loading="isFetchingScreen"
        :class="$style['web-mb-calculator__small-panel']"
      >
        <div>
          <h3 v-html="fields[screenName + '.block.18']?.properties?.text || 'EMI Repayment'"></h3>
        </div>
        <div :class="$style['web-mb-calculator__data-group']">
          <div>
            <p
              :class="$style['web-mb-calculator__data-name']"
              v-html="fields[screenName + '.block.19']?.properties?.text || 'First EMI:'"
            ></p>
            <p
              :class="$style['web-mb-calculator__data-value']"
              v-html="fields[screenName + '.block.21']?.properties?.text || ''"
            ></p>
            <p
              :class="$style['web-mb-calculator__data-description']"
              v-html="fields[screenName + '.block.23']?.properties?.text || ''"
            ></p>
          </div>
          <div>
            <p
              :class="$style['web-mb-calculator__data-name']"
              v-html="fields[screenName + '.block.20']?.properties?.text || 'Next EMI’s:'"
            ></p>
            <p
              :class="$style['web-mb-calculator__data-value']"
              v-html="fields[screenName + '.block.22']?.properties?.text || ''"
            ></p>
            <p
              :class="$style['web-mb-calculator__data-description']"
              v-html="fields[screenName + '.block.24']?.properties?.text || ''"
            ></p>
          </div>
        </div>
        <div :class="$style['web-mb-calculator__expansion-panel-icon-wrap']">
          <div
            :class="[
              $style['web-mb-calculator__expansion-panel-icon'],
              { [$style['web-mb-calculator__expansion-panel-icon--toggle']]: showExpansionPanel }
            ]"
            @click="toggleExpansionPanel"
          ></div>
        </div>
        <div
          v-if="showExpansionPanel"
          :class="$style['web-mb-calculator__expansion-panel']"
        >
          <p
            :class="$style['web-mb-calculator__expansion-panel-text']"
            v-html="fields[screenName + '.block.25']?.properties?.text || 'Comissions & fees:'"
          ></p>

          <div :class="$style['web-mb-calculator__expansion-panel-data']">
            <div>
              <p
                :class="$style['web-mb-calculator__expansion-panel-data-name']"
                v-html="fields[screenName + '.block.26']?.properties?.text || 'Total payable:'"
              ></p>
              <p
                :class="$style['web-mb-calculator__expansion-panel-data-value']"
                v-html="fields[screenName + '.block.31']?.properties?.text || ''"
              ></p>
            </div>
            <div>
              <p
                :class="$style['web-mb-calculator__expansion-panel-data-name']"
                v-html="fields[screenName + '.block.27']?.properties?.text || 'Last payment:'"
              ></p>
              <p
                :class="$style['web-mb-calculator__expansion-panel-data-value']"
                v-html="fields[screenName + '.block.32']?.properties?.text || ''"
              ></p>
            </div>
            <div>
              <div :class="$style['web-mb-calculator__help-wrap']">
                <!-- .webTooltip.29 -->
                <TooltipComponent text="The Basic Term Plan of 5 months is free. Term plans of 10 or 20 months are chargeable with a one-time fee. You can always change the term, before the end of your Grace Period">
                  <!-- .webImage.28 -->
                  <div :class="$style['web-mb-calculator__help-icon']"></div>
                </TooltipComponent>
                <p
                  :class="$style['web-mb-calculator__expansion-panel-data-name']"
                  v-html="fields[screenName + '.block.30']?.properties?.text || 'Optional term fee:'"
                ></p>
              </div>
              <p
                :class="$style['web-mb-calculator__expansion-panel-data-value']"
                v-html="fields[screenName + '.block.33']?.properties?.text || ''"
              ></p>
            </div>
            <div>
              <div :class="$style['web-mb-calculator__help-wrap']">
                <!-- .webTooltip.34 -->
                <TooltipComponent text="This is a one-time processing fee of ₹1,000 on your first withdrawal. All your subsequent withdrawals will be free">
                  <!-- .webImage.35 -->
                  <div :class="$style['web-mb-calculator__help-icon']"></div>
                </TooltipComponent>
                <p
                  :class="$style['web-mb-calculator__expansion-panel-data-name']"
                  v-html="fields[screenName + '.block.36']?.properties?.text || 'Processing fee:'"
                ></p>
              </div>
              <p
                :class="$style['web-mb-calculator__expansion-panel-data-value']"
                v-html="fields[screenName + '.block.36']?.properties?.text || ''"
              ></p>
            </div>
            <div>
              <p
                :class="$style['web-mb-calculator__expansion-panel-data-name']"
                v-html="fields[screenName + '.block.37']?.properties?.text || 'Interest:'"
              ></p>
              <p
                :class="$style['web-mb-calculator__expansion-panel-data-value']"
                v-html="fields[screenName + '.block.38']?.properties?.text || ''"
              ></p>
            </div>
            <div>
              <p
                :class="$style['web-mb-calculator__expansion-panel-btn']"
                v-html="fields[screenName + '.button.main.22']?.properties?.text || 'Charges:'"
                @click="openModal"
              ></p>
            </div>
          </div>
        </div>
      </PanelComponent>
    </div>
    <!-- widget -->
    <ModalComponent
      title="Charges"
      :showModal="showModal"
      @close="closeModal"
    >
      <div :class="$style['web-mb-calculator__table']">
        <div :class="$style['web-mb-calculator__table-row']">
          <div>
            <p :class="$style['web-mb-calculator__table-col-header']">Processing fee (calculated from the amount of the approved limit):</p>
            <p>Up to 60,000 – 1.5% or ₹500</p>
            <p>₹60,000 to ₹1,00,000: 2%</p>
            <p>₹1,00,000 to ₹1,50,000: 2.5%</p>
            <p>Above ₹1,50,000: 3%</p>
          </div>
          <div>
            <div>
              <p :class="$style['web-mb-calculator__table-col-header']">Grace period:</p>
              <p>Until the 20th day of the month following the month of receiving the loan</p>
            </div>
            <div>
              <p :class="$style['web-mb-calculator__table-col-header']">Interest rate:</p>
              <p>36% per annum</p>
            </div>
          </div>
        </div>

        <div :class="$style['web-mb-calculator__table-row']">
          <div>
            <p :class="$style['web-mb-calculator__table-col-header']">Late payment penalty:</p>
            <p>4.5% per month for the amount of overdue debt (accrued on a daily basis)</p>
          </div>
          <div>
            <p :class="$style['web-mb-calculator__table-col-header']">Early repayment fee:</p>
            <p>4% of the early repayment amount. The minimum amount of early repayment is ₹10,000</p>
          </div>
        </div>

        <div :class="$style['web-mb-calculator__table-row']">
          <div>
            <p :class="$style['web-mb-calculator__table-col-header']">EMI reschedule (optional, charged when the payment date is postponed):</p>
            <p>₹750</p>
          </div>
          <div>
            <p :class="$style['web-mb-calculator__table-col-header']">Limit increasing fee (optional, charged only when using the service)</p>
            <p>₹250</p>
          </div>
        </div>

        <div :class="$style['web-mb-calculator__table-row']">
          <div>
            <p :class="$style['web-mb-calculator__table-col-header']">Optional term fee:</p>
            <p>5 months – free</p>
            <p>10 months – ₹999</p>
            <p>20 months – ₹1499</p>
          </div>
          <div></div>
        </div>
      </div>
    </ModalComponent>
  </div>
</template>

<script>
import PanelComponent from '@/components/app/ui/PanelComponent.vue'
import SectionHeaderComponent from '@/components/ui/SectionHeaderComponent.vue'
import TooltipComponent from '@/components/app/ui/TooltipComponent.vue'
import ModalComponent from '@/components/app/ui/ModalComponent.vue'

import currentStepMixin from '@/mixins/currentStepMixin'

export default {
  name: 'CalculatorScreen',
  components: {
    PanelComponent,
    SectionHeaderComponent,
    TooltipComponent,
    ModalComponent
  },
  mixins: [
    currentStepMixin
  ],
  data: () => {
    return {
      rangeValue: 40000,
      radioValue: '',
      showExpansionPanel: false,
      showModal: false
    }
  },
  methods: {
    toggleExpansionPanel () {
      this.showExpansionPanel = !this.showExpansionPanel
    },
    openModal () {
      console.log(this.nodeData[this.screenName + '.input.range.3'])
      this.showModal = true
    },
    closeModal () {
      this.showModal = false
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-calculator {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__col {
    flex: 1;
  }

  &__panel {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: fit-content;
    justify-content: space-between;

    &-row {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  &__small-panel {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-align: center;
    flex: 1;
  }

  &__description {
    color: #A0A0A0;
    font-size: 0.75em;
  }

  &__radio-group {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }

  &__header {
    padding: 0;
  }

  &__small-text {
    font-size: 0.75em;
  }

  &__data-name {
    color: #5A717C;
  }

  &__data-value {
    font-size: 2em;
    font-weight: 600;
  }

  &__data-name,
  &__data-description {
    font-size: 0.75em;
  }

  &__data-group {
    display: flex;
    justify-content: space-evenly;
  }

  &__expansion-panel-icon {
    height: 1.5rem;
    width: 1.5rem;
    background-image: url(../../../assets/icons/app-toggle-icon.svg);
    background-repeat: no-repeat;
    background-position: center center;
    transition: transform .15s ease-in-out;
    cursor: pointer;

    &--toggle {
      transform: rotate(180deg);
    }
  }

  &__help-icon {
    height: 1em;
    width: 1em;
    background-image: url(../../../assets/icons/help-icon.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    transition: background-image .15s ease-in-out;
    cursor: pointer;

    &:hover {
      background-image: url(../../../assets/icons/full-help-icon.svg);
    }
  }

  &__help-wrap {
    display: inline-flex;
    gap: 0.25rem;
  }

  &__expansion-panel-icon-wrap {
    display: flex;
    justify-content: center;
  }

  &__divider {
    color: #96ADB8;
    text-align: center;
    font-weight: 600;
    // margin: 0.5rem 0;
    font-size: 1.5em;
  }

  &__expansion-panel {
    &-text {
      color: #A0A0A0;
      padding-bottom: 1.5rem;
    }

    &-data {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }

    &-data-name {
      color: #5A717C;
      font-size: 0.75em;
    }

    &-data-value {
      font-weight: 600;
      padding-top: 0.5em;
    }

    &-btn {
      font-size: 0.75em;
      color: #b51ace;
      cursor: pointer;
    }
  }

  &__table {
    display: grid;
    grid-template-columns: auto;
    gap: 0.75rem;
    font-size: 0.875em;
  }

  &__table-row {
    display: grid;
    grid-template-columns: auto;
    gap: 0.75rem;
  }

  &__table-col-header {
    font-size: 0.875em;
    color: #707070;
  }

  &__panels {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  @media (min-width: $min-md) {
    gap: 1.5rem;

    &__panel {
      gap: 2.5rem;
    }

    &__small-panel {
      gap: 2rem;
    }

    &__radio-group {
      flex-wrap: nowrap;
    }

    &__data-description {
      font-weight: 600;
    }

    &__expansion-panel-data {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &__table {
      gap: 1.5rem;
    }

    &__table-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
    }
  }

  @media (min-width: $min-lg) {
    flex-direction: row;
    gap: 2rem;

    &__small-panel {
      text-align: initial;
    }

    &__data-group {
      justify-content: space-between;
    }
  }
}
</style>
