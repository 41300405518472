<template>
  <div
    :class="[
      $style['bpm-input'],
      { [$style[`bpm-input--${size}`]]: size !== null },
      { [$style['bpm-input--web']]: this.view === 'web' }
    ]"
  >
    <div :class="$style['bpm-input__wrap']">
      <input
        :class="classes"
        :disabled="disabled"
        :id="id"
        :max="max"
        :maxlength="maxlength"
        :min="min"
        :minlength="minlength"
        :name="name"
        :readonly="readonly"
        :required="required"
        :step="step"
        :style="style"
        :type="type"
        :value="value"
        @input="handleInput"
        @click="handleClick"
      />
      <label
        :class="$style['bpm-input__label']"
        :for="id"
      >
        <div
          :class="$style['bpm-input__label-text']"
        ><span v-html="label"></span><span v-if="required" :class="$style['bpm-input__label-required']">*</span>
        </div>
      </label>
      <div :class="$style['bpm-input__btn']">
        <template v-if="!isSelect && view !== 'web'">
          <button
            :class="[
              $style['bpm-input__btn-clear'],
              { [this.$style['bpm-input__btn-clear--invalid']]: this.state === false }
            ]"
            @click="clearInput"
          ></button>
          <button v-if="state" :class="$style['bpm-input__btn-success']"></button>
        </template>
        <template v-else-if="isSelect">
          <button :class="[
            $style['bpm-input__btn-chevron'],
            { [this.$style['bpm-input__btn-chevron--invalid']]: this.state === false },
            { [this.$style['bpm-input__btn-chevron--is-options']]: isOptions },
            { [this.$style['bpm-input__btn-chevron--valid']]: this.state === true }
          ]"></button>
        </template>
      </div>
    </div>
    <span
      v-if="error && error !== ''"
      :class="$style['bpm-input__error']"
      v-html="error"
    ></span>
    <span
      v-if="description && description !== ''"
      :class="$style['bpm-input__description']"
      v-html="description"
    ></span>
  </div>
</template>

<script>
import uid from '../../utils/uid'

export default {
  name: 'BpmInput',
  props: {
    backgroundColor: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null
    },
    isSelect: {
      type: Boolean,
      default: false
    },
    isOptions: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    max: {
      type: String,
      default: null
    },
    maxlength: {
      type: String,
      default: null
    },
    min: {
      type: String,
      default: null
    },
    minlength: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null // x-small, small, large
    },
    state: {
      type: Boolean, // null, true, false
      default: null
    },
    step: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text' // text, password, number, date
    },
    value: {
      type: String,
      default: null
    },
    view: {
      type: String,
      default: 'mobile' // mobile, web
    }
  },
  computed: {
    classes () {
      return [
        this.$style['bpm-input__input'],
        { [this.$style['bpm-input__input--disabled']]: this.disabled },
        { [this.$style['bpm-input__input--invalid']]: this.state === false },
        { [this.$style['bpm-input__input--is-full']]: (this.value && this.value !== '') || (this.type && this.type === 'date') },
        { [this.$style[`bpm-input__input--${this.size}`]]: this.size !== null && this.size === 'x-small' },
        { [this.$style['bpm-input__input--valid']]: this.state === true }
      ]
    },
    id () {
      return uid()
    },
    style () {
      // на основе данных цветов должны вычисляться цвета динамики (hover, active, etc.)
      return {
        color: this.color,
        backgroundColor: this.backgroundColor,
        borderColor: this.state === null && this.backgroundColor
      }
    }
  },
  methods: {
    clearInput() {
      this.$emit('input', '')
    },
    handleClick () {
      this.$emit('click')
    },
    handleInput (e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>

<style lang="scss" module src="./styles/BpmInput.module.scss">
</style>
