<template>
  <PanelComponent
    :class="$style['web-mb-authentication-third-party-otp-check-first__panel']"
    :loading="isFetchingScreen"
  >
    <div :class="$style['web-mb-authentication-third-party-otp-check-first']">
      <div
        :class="[
          $style['web-mb-authentication-third-party-otp-check-first__col'],
          $style['web-mb-authentication-third-party-otp-check-first__content-wrap']
        ]"
      >
        <h2
          :style="fields[screenName + '.block.1']?.inlineStyle"
          v-html="fields[screenName + '.block.1']?.properties?.text || ''"
        ></h2>
        <p
          :class="$style['web-mb-authentication-third-party-otp-check-first__text-content']"
          :style="fields[screenName + '.block.2']?.inlineStyle"
          v-html="fields[screenName + '.block.2']?.properties?.text || ''"
        ></p>
        <bpm-button
          fullwidth
          @click="nextStep"
        >Continue</bpm-button>
      </div>
      <div
        :class="[
          $style['web-mb-authentication-third-party-otp-check-first__col']
        ]"
      >
        <!-- img -->
      </div>
    </div>
  </PanelComponent>
</template>

<script>
import PanelComponent from '@/components/app/ui/PanelComponent.vue'

import currentStepMixin from '@/mixins/currentStepMixin'

export default {
  name: 'AuthenticationThirdPartyOtpCheckFirstScreen',
  components: {
    PanelComponent
  },
  mixins: [
    currentStepMixin
  ]
}
</script>

<style lang="scss" module>
.web-mb-authentication-third-party-otp-check-first {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__panel {
    display: flex;
    flex-direction: column;
  }

  &__text-content {
    color: #707070;
  }

  &__col {
    flex: 1;
  }

  &__content-wrap {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
}

@media (min-width: $min-md) {
  .web-mb-authentication-third-party-otp-check-first {
    flex-direction: row;

    &__content-wrap {
      justify-content: center;
    }
  }
}
</style>
