<template>
  <h2
    :class="[
      $style['web-mb-section-header'],
      { [$style['web-mb-section-header--is-wider']]: isWider }
    ]"
  >
    <slot></slot>
  </h2>
</template>

<script>
export default {
  name: 'SectionHeaderComponent',
  props: {
    isWider: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-section-header {
  padding-bottom: 1.5rem;
  background: #A92DD2;
  background: linear-gradient(93.21deg, #A92DD2 11.61%, #9747FF 24.44%, #339C89 64.47%, #43B02A 92.93%);
  background-clip: text;
  -webkit-text-fill-color: transparent;

  &--is-wider {
    margin: 0 0.5rem;
  }
  
  @media (min-width: $min-md) {
    padding-bottom: 2rem;
  }
}
</style>
