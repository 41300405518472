var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PanelComponent',{class:_vm.$style['web-mb-user-registered__panel'],attrs:{"loading":_vm.isFetchingScreen}},[_c('div',{class:_vm.$style['web-mb-user-registered__back-btn']},[_c('BackButtonComponent',{on:{"click":_vm.prevStep}})],1),_c('div',{class:_vm.$style['web-mb-user-registered']},[_c('div',{class:[
        _vm.$style['web-mb-user-registered__col'],
        _vm.$style['web-mb-user-registered__content'],
      ]},[_c('h2',{domProps:{"innerHTML":_vm._s(_vm.fields[_vm.screenName + '.block.1']?.properties?.text || 'Welcome back!')}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.fields[_vm.screenName + '.block.2']?.properties?.text || '')}}),_c('div',{class:_vm.$style['web-mb-user-registered__download-wrap']},[_c('div',{class:_vm.$style['web-mb-user-registered__qr-code-img']},[_c('ImgComponent',{attrs:{"src":_vm.qrCodeImg,"alt":"QR Code"}})],1),_c('div',{class:_vm.$style['web-mb-user-registered__download-links']},[_c('ImgComponent',{attrs:{"src":_vm.googlePlayImg,"alt":"Google Play"}}),_c('ImgComponent',{attrs:{"src":_vm.appStoreImg,"alt":"App Store"}})],1),_c('div',{class:_vm.$style['web-mb-user-registered__download-links-mob']},[_c('ImgComponent',{attrs:{"src":_vm.googlePlayImgMob,"alt":"Google Play"}}),_c('ImgComponent',{attrs:{"src":_vm.appStoreImgMob,"alt":"App Store"}})],1)])]),_c('div',{class:[
        _vm.$style['web-mb-user-registered__col'],
        _vm.$style['web-mb-user-registered__img-wrap']
      ]},[_c('ImgComponent',{attrs:{"src":_vm.downloadImg,"alt":"Image"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }