var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PanelComponent',{class:_vm.$style['web-mb-unsuccessfully__panel'],attrs:{"loading":_vm.isFetchingScreen}},[_c('div',{class:_vm.$style['web-mb-unsuccessfully']},[_c('div',{class:[
        _vm.$style['web-mb-unsuccessfully__col'],
        _vm.$style['web-mb-unsuccessfully__content'],
      ]},[_c('h2',{domProps:{"innerHTML":_vm._s(_vm.fields[_vm.screenName + '.block.1']?.properties?.text || 'Your application could not be approved')}}),_c('div',{class:_vm.$style['web-mb-unsuccessfully__content-download']},[_c('div',{class:_vm.$style['web-mb-unsuccessfully__col']},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.fields[_vm.screenName + '.block.3']?.properties?.text || '')}}),_c('ul',{class:_vm.$style['web-mb-unsuccessfully__content-list']},_vm._l((_vm.fields[_vm.screenName + '.block.bullet.5']?.properties?.listData),function(item,index){return _c('li',{key:index,domProps:{"innerHTML":_vm._s(item)}})}),0)]),_c('div',{class:[_vm.$style['web-mb-unsuccessfully__col'], _vm.$style['web-mb-unsuccessfully__img-mob-wrap']]},[_c('ImgComponent',{attrs:{"src":_vm.download2Img,"alt":"Image"}})],1)])]),_c('div',{class:[
        _vm.$style['web-mb-unsuccessfully__col'],
        _vm.$style['web-mb-unsuccessfully__img-wrap']
      ]},[_c('ImgComponent',{attrs:{"src":_vm.download2Img,"alt":"Image"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }