<template>
  <div
    :class="[
      $style['bpm-otp-input'],
      { [$style[`bpm-otp-input--${size}`]]: size !== null }
    ]"
    ref="bpmOtp"
  >
    <div :class="$style['bpm-otp-input__container']" :id="id">
      <bpm-otp-custom-input
        v-for="n in number"
        :class="classes"
        :index="n"
        :key="n"
        :maxlength="1"
        placeholder="•"
        :ref="`bpmOtpInput${n}`"
        :style="{ width: width + 'px' }"
        type="text"
        @clear="handleClear"
        @input="handleInput"
      />
    </div>
    <span v-if="error && error !== ''" :class="$style['bpm-otp-input__error']">{{ error }}</span>
  </div>
</template>

<script>
import BpmOtpCustomInput from './components/BpmOtpCustomInput'

import uid from '../../utils/uid'

export default {
  name: 'BpmOtpInput',
  components: {
    BpmOtpCustomInput
  },
  props: {
    error: {
      type: String,
      default: null
    },
    number: {
      type: Number,
      default: 0
    },
    size: {
      type: String,
      default: null // x-small, small, large
    },
    state: {
      type: Boolean, // null, true, false
      default: null
    }
  },
  data: () => {
    return {
      wrapWidth: null,
      currentIndex: 1,
      result: []
    }
  },
  computed: {
    classes () {
      return [
        this.$style['bpm-otp-input__custom-input'],
        { [this.$style['bpm-otp-input__custom-input--invalid']]: this.state === false },
        { [this.$style['bpm-otp-input__custom-input--valid']]: this.state === true }
      ]
    },
    id () {
      return uid()
    },
    resultOutput () {
      let str = ''
      this.result.forEach(elem => {
        str += elem.value
      })
      return str
    },
    width () {
      return this.number && this.number !== 0 ? (this.wrapWidth - ((this.number - 1) * 16)) / this.number : 0
    }
  },
  methods: {
    changeResult (value, index) {
      this.result.forEach(elem => {
        if (elem.index === index) {
          elem.value = value
        }
      })
    },
    getWrapWidth () {
      this.wrapWidth = this.$refs?.bpmOtp?.offsetWidth
    },
    handleClear ({ value, index }) {
      this.currentIndex = index === this.number && value !== '' ? index : value !== '' ? index : index - 1
      this.changeResult('', this.currentIndex)

      this.$emit('change', this.resultOutput)

      if ((index > 1 && index < this.number || (index === this.number)) && value === '') {
        this.$refs[`bpmOtpInput${this.currentIndex}`][0].$el.focus()
      }
    },
    handleInput ({ value, index }) {
      if (value !== '') {
        this.currentIndex = index + 1
        this.changeResult(value, index)

        this.$emit('change', this.resultOutput)

        if (index !== this.number) {
          this.$refs[`bpmOtpInput${this.currentIndex}`][0].$el.focus()
        }
      }
    },
    initResult () {
      for (let i = 0; i < this.number; i++) {
        this.result.push({
          index: i + 1,
          value: ''
        })
      }
    }
  },
  created () {
    window.addEventListener('resize', this.getWrapWidth)

    this.initResult()
  },
  mounted () {
    this.getWrapWidth()
  },
  destroyed () {
    window.removeEventListener('resize', this.getWrapWidth)
  }
}
</script>

<style lang="scss" module src="./styles/BpmOtpInput.module.scss">
</style>
