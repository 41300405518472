<template>
  <div
    :class="[
      $style['bpm-checkbox'],
      { [$style[`bpm-checkbox--${size}`]]: size !== null }
    ]"
  >
    <label
      :class="[
        $style['bpm-checkbox__label'],
        { [$style['bpm-checkbox__label--disabled']]: disabled }
      ]"
      :for="id"
    >
      <input
        :checked="checked"
        :class="[
          $style['bpm-checkbox__input'],
          { [$style['bpm-checkbox__input--invalid']]: error }
        ]"
        :disabled="disabled"
        :id="id"
        :name="name"
        :required="required"
        type="checkbox"
        :value="value"
        @change="handleChange"
      />
      <div>
        <span v-if="label && label !== ''" v-html="label"></span>
        <slot v-else></slot>
        <div v-if="error && error !== ''" :class="$style['bpm-checkbox__error']">{{ error }}</div>
      </div>
    </label>
  </div>
</template>

<script>
import uid from '../../utils/uid'

// если что-то передается в slot, то желательно оборачивать содержимое в span

export default {
  name: 'BpmCheckbox',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null // x-small, small, large
    },
    value: {
      type: String,
      default: null
    }
  },
  computed: {
    id () {
      return uid()
    }
  },
  methods: {
    handleChange (e) {
      this.$emit('change', e.target.checked)
    }
  }
}
</script>

<style lang="scss" module src="./styles/BpmCheckbox.module.scss">
</style>
