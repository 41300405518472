import { fetchCsrf, fetchStep } from '@/libs/api'
import steps from '@/components/app/steps'

// todo: вынести содержимое задавать отдельно
const stepRequestBody = {
  deviceId: '1',
  projectId: 'web_process',
  lang: 'en',
}

export default {
  data: () => {
    return {
      access: null,
      refresh: null,
      csrftoken: null,
      isFetchingScreen: true,
      currentNode: null,
    }
  },
  computed: {
    currentScreen () {
      // todo: ScreenModel
      const currentScreen = this.currentNode?.screens?.[0]
      const currentWidgets = this.currentNode?.screens?.[0]?.widgets

      const stepComponent = steps[currentScreen?.name] || steps['webLoanProcessing.creditLineIndia.screen_1']

      return {
        ...currentScreen,
        ...currentWidgets,
        ...stepComponent
      }
    }
  },
  methods: {
    async initStep () {
      // todo: вынести и перезапускать
      const csrfResponse = await fetchCsrf()
      this.csrftoken = csrfResponse?.data?.response?.csrftoken

      const stepResponse = await fetchStep(stepRequestBody)
      this.access = stepResponse?.data?.auth?.access
      this.refresh = stepResponse?.data?.auth?.refresh

      if (this.access) {
        this.currentNode = await this.getNodeData({})
      }
    },
    async getNodeData ({ destination, screenId, nodeData = {} }) {
      this.isFetchingScreen = true

      const headers = {
        Authorization: `Bearer ${this.access}`,
        'X-CSRFToken': btoa(this.csrftoken)
      }

      let body = { ...stepRequestBody }

      if (destination && screenId) {
        body = {
          ...body,
          destination,
          screenId,
          nodeData,
        }
      }

      try {
        const response = await fetchStep(body, headers)
        const { node } = response.data
        return node
      } catch (e) {
        console.log(e)
        return this.currentNode
      } finally {
        this.isFetchingScreen = false
      }
    },
    async fetchNextStep ({ nodeData }) {
      this.currentNode = await this.getNodeData({
        destination: 'next',
        screenId: this.currentScreen?.id,
        nodeData
      })
    },
    async fetchPrevStep ({ nodeData }) {
      this.currentNode = await this.getNodeData({
        destination: 'previous',
        screenId: this.currentScreen?.id,
        nodeData
      })
    },
    async nextStep (nodeData) {
      if (this.isFetchingScreen) return
      await this.fetchNextStep({ nodeData })
    },
    async prevStep (nodeData) {
      if (this.isFetchingScreen) return
      await this.fetchPrevStep({ nodeData })
    }
  },
  async mounted () {
    await this.initStep()
  }
}
