import CalculatorScreen from '@/components/app/screens/CalculatorScreen.vue'
import IdentificationScreen from '@/components/app/screens/IdentificationScreen.vue'
import AuthenticationOtpCheckMobileFirstScreen from '@/components/app/screens/AuthenticationOtpCheckMobileFirstScreen.vue'
import AuthenticationOtpCheckMobileSecondScreen from '@/components/app/screens/AuthenticationOtpCheckMobileSecondScreen.vue'
import AuthenticationThirdPartyOtpCheckFirstScreen from '@/components/app/screens/AuthenticationThirdPartyOtpCheckFirstScreen.vue'
import AuthenticationThirdPartyOtpCheckSecondScreen from '@/components/app/screens/AuthenticationThirdPartyOtpCheckSecondScreen.vue'
import PersonalDataFormScreen from '@/components/app/screens/PersonalDataFormScreen.vue'
import AddressFormScreen from '@/components/app/screens/AddressFormScreen.vue'
import DocumentsFormScreen from '@/components/app/screens/DocumentsFormScreen.vue'
import OccupationScreen from '@/components/app/screens/OccupationScreen.vue'
import IncomeDetailsEmployedScreen from '@/components/app/screens/IncomeDetailsEmployedScreen.vue'
import IncomeDetailsSelfEmployedScreen from '@/components/app/screens/IncomeDetailsSelfEmployedScreen.vue'
import IncomeDetailsStudentUnemployedScreen from '@/components/app/screens/IncomeDetailsStudentUnemployedScreen.vue'
import OfficeAddressScreen from '@/components/app/screens/OfficeAddressScreen.vue'
import StatusScreen from '@/components/app/screens/StatusScreen.vue'
import ApprovalAmountGreaterScreen from '@/components/app/screens/ApprovalAmountGreaterScreen.vue'
import ApprovalAmountEqualOrLessScreen from '@/components/app/screens/ApprovalAmountEqualOrLessScreen.vue'
import ApprovalAmountEqualScreen from '@/components/app/screens/ApprovalAmountEqualScreen.vue'
import UserRegisteredScreen from '@/components/app/screens/UserRegisteredScreen.vue'
import UnsuccessfullyScreen from '@/components/app/screens/UnsuccessfullyScreen.vue'

const steps = {
  // -
  'webLoanProcessing.creditLineIndia.screen_1': {
    component: CalculatorScreen,
  },
  // +
  'WebIdentification.mobileNumber.screen_1': {
    component: IdentificationScreen,
  },
  // + otp
  'WebAuthentication.otpCheckMobile.screen_1': {
    component: AuthenticationOtpCheckMobileFirstScreen,
  },
  // +
  'WebAuthentication.otpCheckMobile.screen_2' : {
    component: AuthenticationOtpCheckMobileSecondScreen,
  },
  // +
  'WebAuthentication.thirdPartyOtpCheck.screen_1': {
    component: AuthenticationThirdPartyOtpCheckFirstScreen,
  },
  // + otp
  'WebAuthentication.thirdPartyOtpCheck.screen_2': {
    component: AuthenticationThirdPartyOtpCheckSecondScreen,
  },
  // +
  'WebForm1.screen_1': {
    component: PersonalDataFormScreen,
  },
  // +
  'WebForm1.screen_2': {
    component: AddressFormScreen,
  },
  // +
  'WebForm1.screen_3': {
    component: DocumentsFormScreen,
  },
  // +
  'WebQuestionnairePart2.screen_1': {
    component: OccupationScreen,
  },
  // +
  'webForm2.selfEmployed.screen_1': {
    component: IncomeDetailsSelfEmployedScreen,
  },
  // +
  'webForm2.studentUnemployed.screen_1': {
    component: IncomeDetailsStudentUnemployedScreen,
  },
  // +
  'webForm2.employed.screen_1': {
    component: IncomeDetailsEmployedScreen,
  },
  // +
  'webForm2.employed.screen_2': {
    component: OfficeAddressScreen,
  },
  // -
  'WebStatus.main.screen_1': {
    component: StatusScreen,
  },
  // +
  'downloadApp.approvalAmountGreater.screen_1': {
    component: ApprovalAmountGreaterScreen,
  },
  // +
  'downloadApp.approvalAmountEqualOrLess.screen_1': {
    component: ApprovalAmountEqualOrLessScreen,
  },
  // +
  'downloadApp.approvalAmountEqual.screen_1': {
    component: ApprovalAmountEqualScreen,
  },
  // +
  'downloadApp.userRegistered.screen_1': {
    component: UserRegisteredScreen,
  },
  // +
  'downloadApp.unsuccessfully.screen_1': {
    component: UnsuccessfullyScreen,
  }
}

export default steps
