<template>
  <div :class="[$style['bpm-web-otp-input'], { [$style[`bpm-web-otp-input--${size}`]]: size !== null }]" ref="bpmOtp">
    <bpm-label
      v-if="label && label !== ''"
      :id="id"
      :label="label"
      :required="required"
      :size="size"
    />
    <div :id="id" :class="$style['bpm-web-otp-input__container']">
      <bpm-web-otp-input-input
        v-for="n in number"
        :class="classes"
        :index="n"
        :key="n"
        :maxlength="1"
        :ref="`bpmOtpInput${n}`"
        :style="{ width: width + 'px' }"
        :type="type"
        @clear="handleClear"
        @input="handleInput"
      />
    </div>
    <span v-if="error && error !== ''" :class="$style['bpm-web-otp-input__error']">{{ error }}</span>
    <span v-if="description && description !== ''" :class="$style['bpm-web-otp-input__description']">{{ description }}</span>
  </div>
</template>

<script>
import BpmWebOtpInputInput from './components/BpmWebOtpInputInput'
import BpmLabel from '../BpmLabel'
import uid from '../../utils/uid'

export default {
  name: 'BpmWebOtpInput',
  components: {
    BpmWebOtpInputInput,
    BpmLabel
  },
  props: {
    description: {
      type: String,
      default: null
    },
    error: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    number: {
      type: Number,
      default: 0
    },
    required: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null // x-small, small, large
    },
    state: {
      type: Boolean, // null, true, false
      default: null
    },
    type: {
      type: String,
      default: 'text' // text, password, number
    }
  },
  data: () => {
    return {
      wrapWidth: null,
      currentIndex: 1,
      result: []
    }
  },
  computed: {
    classes () {
      return [
        this.$style['bpm-web-otp-input__input'],
        { [this.$style['bpm-web-otp-input__input--valid']]: this.state === true },
        { [this.$style['bpm-web-otp-input__input--invalid']]: this.state === false }
      ]
    },
    id () {
      return uid()
    },
    resultOutput () {
      let str = ''
      this.result.forEach(elem => {
        str += elem.value
      })
      return str
    },
    width () {
      return this.number && this.number !== 0 ? (this.wrapWidth - ((this.number - 1) * 16)) / this.number : 0
    }
  },
  methods: {
    changeResult (value, index) {
      this.result.forEach(elem => {
        if (elem.index === index) {
          elem.value = value
        }
      })
    },
    getWrapWidth () {
      this.wrapWidth = this.$refs?.bpmOtp?.offsetWidth
    },
    handleClear ({ value, index }) {
      this.currentIndex = index === this.number && value !== '' ? index : value !== '' ? index : index - 1
      this.changeResult('', this.currentIndex)

      this.$emit('change', this.resultOutput)

      if ((index > 1 && index < this.number || (index === this.number)) && value === '') {
        this.$refs[`bpmOtpInput${this.currentIndex}`][0].$el.focus()
      }
    },
    handleInput ({ value, index }) {
      if (value !== '') {
        this.currentIndex = index + 1
        this.changeResult(value, index)

        this.$emit('change', this.resultOutput)

        if (index !== this.number) {
          this.$refs[`bpmOtpInput${this.currentIndex}`][0].$el.focus()
        }
      }
    },
    initResult () {
      for (let i = 0; i < this.number; i++) {
        this.result.push({
          index: i + 1,
          value: ''
        })
      }
    }
  },
  created () {
    window.addEventListener('resize', this.getWrapWidth)

    this.initResult()
  },
  mounted () {
    this.getWrapWidth()
  },
  destroyed () {
    window.removeEventListener('resize', this.getWrapWidth)
  }
}
</script>

<style lang="scss" module src="./styles/BpmWebOtpInput.module.scss">
</style>
