<template>
  <header :class="$style['web-mb-header']">
    <ContainerComponent>
      <div :class="$style['web-mb-header__panel']">
        <div :class="$style['web-mb-header__row']">
          <a href="/">
            <ImgComponent
              :src="logoImg"
              alt="Logo"
              :imgStyle="{ width: '85px', height: 'auto' }"
            />
          </a>
          <nav :class="[$style['web-mb-header__nav']]">
            <div
              v-for="route in routes"
              :key="route.name"
            >
              <a :href="route.path">{{ route.title }}</a>
            </div>
          </nav>
          <div
            :class="[
              $style['web-mb-header__mb-nav-icon'],
              { [$style['web-mb-header__mb-nav-icon--is-open']]: isOpen }
            ]"
            @click="toggleMenu"
          ></div>
        </div>
        <nav
          :class="[
            $style['web-mb-header__row'],
            $style['web-mb-header__mb-nav'],
            { [$style['web-mb-header__mb-nav--is-open']]: isOpen }
          ]"
        >
          <div
            v-for="route in routes"
            :key="route.name"
          >
            <a :href="route.path">{{ route.title }}</a>
          </div>
        </nav>
      </div>
    </ContainerComponent>
  </header>
</template>

<script>
import ContainerComponent from '@/components/ui/ContainerComponent.vue'
import ImgComponent from '@/components/ui/ImgComponent.vue'

import logoImg from '@/assets/images/logo-img.svg'

import { routes } from '@/router'

export default {
  name: 'HeaderComponent',
  components: {
    ContainerComponent,
    ImgComponent
  },
  data: () => {
    return {
      routes,
      logoImg,
      isOpen: false
    }
  },
  methods: {
    toggleMenu () {
      this.isOpen = !this.isOpen
    }
  }
  // event resize isOpen
}
</script>

<style lang="scss" module>
.web-mb-header {
  margin: 1.5rem $section-x-indent;

  &__panel {
    background-color: #FFFFFF;
    border-radius: 1.5rem;
    padding: 0.75rem 1.5rem;
    box-shadow: 0px 5px 30px rgba(79, 105, 118, 0.08);
  }

  &__row {
    display: flex;
    justify-content: space-between;
    gap: 3.5rem;
  }

  &__nav {
    display: none;
    font-weight: 500;
  }

  &__mb-nav {
    display: none;
    font-size: 1.375em;
    font-weight: 600;

    &-icon {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      background-image: url(../../assets/icons/menu-icon.svg);
      background-repeat: no-repeat;
      background-position: center center;
      cursor: pointer;

      &--is-open {
        background-image: url(../../assets/icons/close-menu-icon.svg);
      }
    }

    &--is-open {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      padding-bottom: 0.75rem;
      padding-top: 1.5rem;
    }
  }

  &__nav a:link,
  &__nav a:visited,
  &__mb-nav a:link,
  &__mb-nav a:visited,
  &__nav a:hover,
  &__nav a:focus,
  &__nav a:active,
  &__mb-nav a:hover,
  &__mb-nav a:focus,
  &__mb-nav a:active {
    color: #0E1A22;
    text-decoration: none;
    transition: color .15s ease-in-out;
  }

  &__nav a:hover,
  &__nav a:focus,
  &__nav a:active,
  &__mb-nav a:hover,
  &__mb-nav a:focus,
  &__mb-nav a:active {
    color: #B51ACE;
  }

  @media (min-width: $min-md) {
    margin: 1.5rem $section-x-indent-md;
    
    &__panel {
      padding: 1.75rem 2rem;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-radius: 4rem;
    }

    &__nav {
      display: flex;
      gap: 3.5rem;
    }

    &__mb-nav,
    &__mb-nav-icon {
      display: none;
    }
  }

  @media (min-width: $min-lg) {
    margin: 1.5rem $section-x-indent-lg;
  }
}
</style>
