<template>
  <div :class="[$style['bpm-action-text'], { [$style[`bpm-action-text--${size}`]]: size !== null }]" @click="$emit('click')">
    <div :class="$style['bpm-action-text__wrap']">
      <div :class="$style['bpm-action-text__text-wrap']">
        <template v-if="leftIcon" >
          <img :src="leftIcon" alt="...">
        </template>
        <span :class="$style['bpm-action-text__text']">{{ text }}</span>
      </div>
      <template v-if="rightIcon" >
        <img :src="rightIcon" alt="...">
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BpmActionText',
  props: {
    text: {
      type: String,
      default: null
    },
    leftIcon: {
      type: String,
      default: null
    },
    rightIcon: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: null // x-small, small, large
    }
  }
}
</script>

<style lang="scss" module src="./styles/BpmActionText.module.scss">
</style>
