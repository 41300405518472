export class UploadableFile {
  constructor (file) {
    this.file = file
    this.name = file.name
    this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`
    this.url = URL.createObjectURL(file)
    this.status = null,
    this.size = formatFileSize(file.size)
  }
}

export function formatFileSize (bytes, decimalPoint) {
  if (bytes === 0) return '0 Bytes'
  let k = 1000,
      dm = decimalPoint || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}
