<template>
  <div
    :class="[
      $style['web-mb-panel'],
      { [$style['web-mb-panel--medium']]: medium },
      { [$style['web-mb-panel--small']]: small },
      { [$style['web-mb-panel--loading']]: loading }
    ]"
  >
    <template v-if="loading">
      <bpm-timer mode="large" />
    </template>
    <slot v-else></slot>
  </div>
</template>

<script>
export default {
  name: 'PanelComponent',
  props: {
    medium: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-panel {
  background-color: #FFFFFF;
  border-radius: 1.5rem;
  padding: 1.5rem 1rem;
  min-height: 39rem; // 624px

  &--small {
    min-height: fit-content;
  }

  &--loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: $min-md) {
    border-radius: 2rem;
    padding: 2rem 1.5rem;

    &--medium,
    &--small {
      padding: 2rem 2rem;
    }
  }

  @media (min-width: $min-lg) {
    padding: 3rem 3rem;

    &--medium {
      padding: 2rem 3rem;
    }

    &--small {
      padding: 2rem 2rem;
    }
  }
}
</style>
