<template>
  <div
    :class="[
      $style['bpm-range'],
      { [$style[`bpm-range--${size}`]]: size !== null },
      { [$style['bpm-range--web']]: this.view === 'web' }
    ]"
  >
    <div :class="$style['bpm-range__wrap']">
      <div v-if="showInput" :class="$style['bpm-range__text-input-wrap']">
        <div
          v-if="getCurrency"
          :class="[
            $style['bpm-range__currency'],
            { [$style['bpm-range__currency--disabled']]: disabled },
            { [$style[`bpm-range__currency--${size}`]]: size !== null && size === 'x-small' }
          ]"
        >{{ getCurrency }}</div>
        <input
          :class="[
            $style['bpm-range__text-input'],
            { [$style['bpm-range__text-input--disabled']]: disabled },
            { [$style[`bpm-range__text-input--${size}`]]: size !== null && size === 'x-small' }
          ]"
          :disabled="disabled"
          :id="id"
          :max="max"
          :min="min"
          :placeholder="placeholder"
          :readonly="readonlyInput"
          :required="required"
          type="number"
          :value="value"
          @input="handleInput"
        />
      </div>
      <input
        :class="[
          $style['bpm-range__range-input'],
          { [$style['bpm-range__range-input--disabled']]: disabled }
        ]"
        :disabled="disabled"
        :max="max"
        :min="min"
        :name="name"
        ref="bpmRange"
        :step="step"
        type="range"
        :value="value"
        @input="handleInput"
      />
    </div>
    <div :class="[
      $style['bpm-range__values'],
      { [$style['bpm-range__values--disabled']]: disabled }
    ]">
      <div :class="$style['bpm-range__min-value']">From {{ getCurrency }}{{ min }}</div>
      <div :class="$style['bpm-range__max-value']">To {{ getCurrency }}{{ max }}</div>
    </div>
  </div>
</template>

<script>
import uid from '../../utils/uid'

export default {
  name: 'BpmRange',
  props: {
    currency: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
      default: 1
    },
    min: {
      type: Number,
      default: 0
    },
    name: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    readonlyInput: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    showInput: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: null // x-small, small, large
    },
    step: {
      type: Number,
      default: 1
    },
    value: {
      type: Number,
      default: 0
    },
    view: {
      type: String,
      default: 'mobile' // mobile, web
    }
  },
  computed: {
    getCurrency () {
      return this.currency && this.currency !== '' ? this.currency : ''
    },
    id () {
      return uid()
    }
  },
  methods: {
    handleInput (e) {
      if (e.target.valueAsNumber >= this.min && e.target.valueAsNumber <= this.max) {
        this.someRange(e.target.valueAsNumber)
        this.$emit('input', e.target.valueAsNumber)
      }
    },
    someRange (value) {
      this.$refs['bpmRange'].style.backgroundSize = (value - this.min) * 100 / (this.max - this.min) + '% 100%'
    }
  },
  mounted: function () {
    this.someRange(this.value)
  }
}
</script>

<style lang="scss" module src="./styles/BpmRange.module.scss">
</style>
