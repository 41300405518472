<template>
  <PanelComponent
    :class="$style['web-mb-income-details-self-employed__panel']"
    :loading="isFetchingScreen"
  >
    <!-- ButtonArrowWidget -->
    <div :class="$style['web-mb-income-details-self-employed__back-btn']">
      <BackButtonComponent @click="prevStep" />
    </div>

    <div :class="$style['web-mb-income-details-self-employed']">
      <div
        :class="[
          $style['web-mb-income-details-self-employed__col'],
          $style['web-mb-income-details-self-employed__form-wrap']
        ]"
      >
        <h2
          :style="fields[screenName + '.block.1']?.inlineStyle"
          v-html="fields[screenName + '.block.1']?.properties?.text || 'Income Details'"
        ></h2>

        <div :class="$style['web-mb-income-details-self-employed__form']">
          <div :class="$style['web-mb-income-details-self-employed__inputs-wrap']">
            <bpm-select
              v-model="industry"
              :options="industryData"
              :placeholder="fields[screenName + '.input.dropDownList.3']?.properties?.placeholder"
              :label="fields[screenName + '.input.dropDownList.3']?.properties?.label"
              :state="!fields[screenName + '.input.dropDownList.3']?.properties?.errorIndicator ? null : false"
              :error="(fields[screenName + '.input.dropDownList.3']?.properties?.errorIndicator && fields[screenName + '.input.dropDownList.3']?.properties?.errorText) ? fields[screenName + '.input.dropDownList.3']?.properties?.errorText : ''"
              view="web"
              :class="$style['web-mb-income-details-self-employed__col']"
            />
            <bpm-input
              v-model="gross"
              :placeholder="fields[screenName + '.input.4']?.properties?.placeholder"
              :label="fields[screenName + '.input.4']?.properties?.label"
              :state="!fields[screenName + '.input.4']?.properties?.errorIndicator ? null : false"
              :error="(fields[screenName + '.input.4']?.properties?.errorIndicator && fields[screenName + '.input.4']?.properties?.errorText) ? fields[screenName + '.input.4']?.properties?.errorText : ''"
              :disabled="fields[screenName + '.input.4']?.properties?.disable"
              view="web"
              :class="$style['web-mb-income-details-self-employed__col']"
            />
          </div>
        </div>
      </div>

      <div
        :class="[
          $style['web-mb-income-details-self-employed__col'],
          $style['web-mb-income-details-self-employed__progress']
        ]"
      >
        <ProgressBarComponent
          :text="fields[screenName + '.webProgressBar.2']?.properties?.text || 'Filling progress'"
          :value="fields[screenName + '.webProgressBar.2']?.properties?.progress || 90"
        >
          <!-- text сломан -->
          <!-- label -->
          <!-- {{ fields[screenName + '.checkbox.5']?.properties?.text }} -->
          <bpm-checkbox
            v-model="confirmCheck"
            :value="String(confirmCheck)"
            :name="screenName + '.checkbox.5'"
            label="I confirm that the monthly incomeof my Household is more than ₹25,000"
            :disabled="fields[screenName + '.checkbox.5']?.properties?.disable"
            :error="fields[screenName + '.checkbox.5']?.properties?.errorText || confirmCheckError"
            :class="$style['web-mb-income-details-self-employed__progress-checkbox']"
          />
          <!-- ButtonNextWidget -->
          <bpm-button
            fullwidth
            @click="validNextStep"
          >Continue</bpm-button>
        </ProgressBarComponent>
      </div>
    </div>
  </PanelComponent>
</template>

<script>
import PanelComponent from '@/components/app/ui/PanelComponent.vue'
import ProgressBarComponent from '@/components/app/ui/ProgressBarComponent.vue'
import BackButtonComponent from '@/components/app/ui/BackButtonComponent.vue'

import currentStepMixin from '@/mixins/currentStepMixin'

export default {
  name: 'IncomeDetailsSelfEmployedScreen',
  components: {
    PanelComponent,
    ProgressBarComponent,
    BackButtonComponent
  },
  mixins: [
    currentStepMixin
  ],
  data: () => {
    return {
      industry: '',
      industryLocalData: [
        {
          id: 1,
          label: "Aggregated Final",
          value: "Aggregated Final",
          disabled: false
        },
        {
          id: 2,
          label: "Agriculture",
          value: "Agriculture",
          disabled: false
        },
        {
          id: 3,
          label: "Construction",
          value: "Construction",
          disabled: false
        },
        {
          id: 4,
          label: "Entertainment",
          value: "Entertainment",
          disabled: false
        },
        {
          id: 5,
          label: "Finance",
          value: "Finance",
          disabled: false
        },
        {
          id: 6,
          label: "Food Industry",
          value: "Food Industry",
          disabled: false
        },
        {
          id: 7,
          label: "Healthcare",
          value: "Healthcare",
          disabled: false
        },
        {
          id: 8,
          label: "Logistic",
          value: "Logistic",
          disabled: false
        },
        {
          id: 9,
          label: "Manufacturing",
          value: "Manufacturing",
          disabled: false
        },
        {
          id: 10,
          label: "Pharmaceutical and Chemical",
          value: "Pharmaceutical and Chemical",
          disabled: false
        },
        {
          id: 11,
          label: "Retail",
          value: "Retail",
          disabled: false
        },
        {
          id: 12,
          label: "Telecommunication",
          value: "Telecommunication",
          disabled: false
        },
        {
          id: 13,
          label: "Tech/IT",
          value: "Tech/IT",
          disabled: false
        },
        {
          id: 14,
          label: "Others",
          value: "Others",
          disabled: false
        }
      ],
      gross: '',
      confirmCheck: false,
      confirmCheckError: '' // todo: state
    }
  },
  computed: {
    currentNodeData () {
      return {
        [this.screenName + '.input.dropDownList.3']: this.industry,
        [this.screenName + '.input.4']: this.gross,
        // checkbox - не требуется передавать
      }
    },
    industryData () {
      const list = this.getArrData(this.fields[this.screenName + '.input.dropDownList.3']?.listData, this.industryLocalData)

      return list.map((item, index) => {
        return {
          id: index + 1,
          label: item,
          value: item,
          disabled: false
        }
      })
    }
  },
  watch: {
    currentNodeData: {
      handler () {
        this.nodeData = this.currentNodeData
      },
      immediate: true
    },
    confirmCheck: {
      handler () {
        this.confirmCheckError = ''
      }
    }
  },
  methods: {
    validNextStep () {
      if (!this.confirmCheck) {
        this.confirmCheckError = 'Required'
        return
      }

      this.nextStep()
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-income-details-self-employed {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  &__panel {
    display: flex;
    flex-direction: column;
  }

  &__back-btn {
    padding-bottom: 1rem;
  }

  &__form {
    padding-top: 1.5rem;
  }

  &__inputs-wrap {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1rem;
  }

  &__form-checkbox {
    font-size: 0.75em;
    padding-top: 0.5rem;
  }

  &__progress {
    padding-top: 0.5rem;
  }

  &__progress-checkbox {
    font-size: 0.875em;
    padding-bottom: 0.75rem;
  }
}

@media (min-width: $min-md) {
  .web-mb-income-details-self-employed {
    &__back-btn {
      padding-bottom: 1.5rem;
    }

    &__col {
      flex: 1;
    }

    &__inputs-wrap {
      flex-direction: row;
      padding-bottom: 1.5rem;
    }

    &__progress {
      flex: 0;
    }
  }
}

@media (min-width: $min-lg) {
  .web-mb-income-details-self-employed {
    flex-direction: row;

    &__back-btn {
      padding-bottom: 2.5rem;
    }

    &__col {
      flex: 1;
    }

    &__form-wrap {
      flex: 2;
    }

    &__form {
      padding-top: 2rem;
      padding-right: 2rem;
    }

    &__progress {
      padding-top: 0;
    }
  }
}
</style>
