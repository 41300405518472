<template>
  <div :class="[$style['bpm-progress'], { [$style[`bpm-progress--${size}`]]: size !== null }]">
    <div :class="$style['bpm-progress__custom-progress']" :style="{ backgroundColor: backgroundColor }">
      <div :class="$style['bpm-progress__custom-value']" :style="{ width: `${value}%`, backgroundColor: color }"></div>
      <progress :class="$style['bpm-progress__progress']" :id="id" :max="max" :value="value">{{ `${value}%` }}</progress>
    </div>
  </div>
</template>

<script>
import uid from '../../utils/uid'

export default {
  name: 'BpmProgress',
  props: {
    backgroundColor: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    },
    max: {
      type: Number,
      default: 1
    },
    size: {
      type: String,
      default: null // x-small, small, large
    },
    value: {
      type: Number,
      default: 0
    }
  },
  computed: {
    id () {
      return uid()
    }
  }
}
</script>

<style lang="scss" module src="./styles/BpmProgress.module.scss">
</style>
