<template>
  <button
    :class="classes"
    :disabled="disabled"
    :name="name"
    :type="type"
    :style="style"
    :value="value"
    @click="handleClick"
  >
    <span :class="$style['bpm-button__btn-text']">
      <slot></slot>
    </span>
  </button>
</template>

<script>
export default {
  name: 'BpmButton',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    fullwidth: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    },
    outline: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    },
    gradient: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: null // x-small, small, large
    },
    type: {
      type: String,
      default: 'button' // button, submit, reset
    },
    value: {
      type: String,
      default: null
    },
    variant: {
      type: String,
      default: 'primary' // primary
    },
    backgroundColor: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    },
    gradientBackgroundColor: {
      type: String,
      default: null
    }
  },
  computed: {
    classes () {
      return [
        this.$style['bpm-button'],
        { [this.$style[`bpm-button--${this.variant}${this.outline ? '-outline' : ''}${this.text ? '-text' : ''}${this.gradient ? '-gradient' : ''}`]]: this.variant },
        { [this.$style['bpm-button--fullwidth']]: this.fullwidth },
        { [this.$style['bpm-button--rounded']]: this.rounded },
        { [this.$style[`bpm-button--${this.size}`]]: this.size !== null }
      ]
    },
    style () {
      return {
        color: this.color,
        backgroundColor: this.backgroundColor,
        borderColor: this.backgroundColor,
        '--bpm-gradient': this.gradientBackgroundColor
      }
    }
  },
  methods: {
    handleClick (e) {
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="scss" module src="./styles/BpmButton.module.scss">
</style>
