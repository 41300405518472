<template>
  <!-- PRODUCT -->
  <section :class="$style['web-mb-product-section']">
    <PagePanelComponent :class="$style['web-mb-product-section__panel-' + product.id]">
      <ContainerComponent :class="$style['web-mb-product-section__container']">
        <div
          :class="[
            $style['web-mb-product-section__row'],
            { [$style['web-mb-product-section__row--reverse']]: product.reverse }
          ]"
        >
          <div :class="$style['web-mb-product-section__col']">
            <span v-html="product.title"></span>

            <ul :class="$style['web-mb-product-section__list']">
              <li
                v-for="(item, index) in product.list"
                :key="index"
                v-html="item"
              ></li>
            </ul>
          </div>
          <div
            :class="[
              $style['web-mb-product-section__col'],
              $style['web-mb-product-section__img-wrap']
            ]"
          >
          </div>
        </div>
      </ContainerComponent>
    </PagePanelComponent>
  </section>
</template>

<script>
import PagePanelComponent from '@/components/ui/PagePanelComponent.vue'
import ContainerComponent from '@/components/ui/ContainerComponent.vue'

export default {
  name: 'ProductSection',
  components: {
    PagePanelComponent,
    ContainerComponent
  },
  props: {
    product: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-product-section {
  margin-bottom: 1.5rem;

  &__container {
    width: 100%;
  }

  &__panel-1,
  &__panel-2,
  &__panel-3,
  &__panel-4 {
    display: flex;
    flex-direction: column;
    justify-content: end;
    min-height: 39rem; // 624px
  }

  &__panel-1 {
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 0) 100%), url(../../../assets/images/products/111.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__panel-2 {
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 05, 1) 40%, rgba(0, 0, 0, 0) 100%), url(../../../assets/images/products/222.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__panel-3 {
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 0) 100%), url(../../../assets/images/products/333.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__panel-4 {
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 05, 1) 40%, rgba(0, 0, 0, 0) 100%), url(../../../assets/images/products/444.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__panel-2,
  &__panel-4 {
    color: #FFFFFF;
  }
  
  &__panel-2 &__list,
  &__panel-4 &__list {
    color: #FFFFFF;
  }

  &__row {
    display: flex;
    flex-direction: column-reverse;
    gap: 1.5rem;
  }

  &__col {
    flex: 1;
  }

  &__list {
    list-style-position: inside;
    color: #5A717C;
    padding-top: 1.5rem;
  }

  &__list li {
    position: relative;
    padding-bottom: 1rem;
    padding-left: 1rem;
    list-style: none;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0.5rem;
      width: 0.5rem;
      height: 0.5rem;
      background-image: url(../../../assets/icons/list-item-icon.svg);
    }
  }

  &__img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: $min-md) {
    &__panel-1 {
      background-image: url(../../../assets/images/products/11.png);
    }

    &__panel-2 {
      background-image: url(../../../assets/images/products/22.png);
    }

    &__panel-3 {
      background-image: url(../../../assets/images/products/33.png);
    }

    &__panel-4 {
      background-image: url(../../../assets/images/products/44.png);
    }

    &__panel-1,
    &__panel-2,
    &__panel-3,
    &__panel-4 {
      justify-content: center;
    }
    
    &__row {
      flex-direction: row;
      // align-items: center;

      &--reverse {
        flex-direction: row-reverse;
      }
    }

    &__list {
      padding-top: 2rem;
    }

    &__list li {
      padding-bottom: 1.5rem;
    }
  }

  @media (min-width: $min-lg) {
    &__panel-1,
    &__panel-2,
    &__panel-3,
    &__panel-4 {
      background-position: initial;
    }

    &__panel-1 {
      background-image: url(../../../assets/images/products/1.png);
    }

    &__panel-2 {
      background-image: url(../../../assets/images/products/2.png);
    }

    &__panel-3 {
      background-image: url(../../../assets/images/products/3.png);
    }

    &__panel-4 {
      background-image: url(../../../assets/images/products/4.png);
    }
  }
}
</style>
