<template>
  <PanelComponent
    :class="$style['web-mb-address-form__panel']"
    :loading="isFetchingScreen"
  >
    <!-- ButtonArrowWidget -->
    <div :class="$style['web-mb-address-form__back-btn']">
      <BackButtonComponent @click="prevStep" />
    </div>

    <div :class="$style['web-mb-address-form']">
      <div
        :class="[
          $style['web-mb-address-form__col'],
          $style['web-mb-address-form__form-wrap']
        ]"
      >
        <h2
          :style="fields[screenName + '.block.1']?.inlineStyle"
          v-html="fields[screenName + '.block.1']?.properties?.text || 'Current address'"
        ></h2>

        <div :class="$style['web-mb-address-form__form']">
          <div :class="$style['web-mb-address-form__inputs-wrap']">
            <bpm-input
              v-model="pin"
              :placeholder="fields[screenName + '.input.3']?.properties?.placeholder"
              :label="fields[screenName + '.input.3']?.properties?.label"
              :state="!fields[screenName + '.input.3']?.properties?.errorIndicator ? null : false"
              :error="(fields[screenName + '.input.3']?.properties?.errorIndicator && fields[screenName + '.input.3']?.properties?.errorText) ? fields[screenName + '.input.3']?.properties?.errorText : ''"
              :disabled="fields[screenName + '.input.3']?.properties?.disable"
              view="web"
              :class="$style['web-mb-address-form__col']"
            />
            <bpm-input
              v-model="locality"
              :placeholder="fields[screenName + '.input.4']?.properties?.placeholder"
              :label="fields[screenName + '.input.4']?.properties?.label"
              :state="!fields[screenName + '.input.4']?.properties?.errorIndicator ? null : false"
              :error="(fields[screenName + '.input.4']?.properties?.errorIndicator && fields[screenName + '.input.4']?.properties?.errorText) ? fields[screenName + '.input.4']?.properties?.errorText : ''"
              :disabled="fields[screenName + '.input.4']?.properties?.disable"
              view="web"
              :class="$style['web-mb-address-form__col']"
            />
          </div>
          <div :class="$style['web-mb-address-form__inputs-wrap']">
            <bpm-input
              v-model="street"
              :placeholder="fields[screenName + '.input.5']?.properties?.placeholder"
              :label="fields[screenName + '.input.5']?.properties?.label"
              :state="!fields[screenName + '.input.5']?.properties?.errorIndicator ? null : false"
              :error="(fields[screenName + '.input.5']?.properties?.errorIndicator && fields[screenName + '.input.5']?.properties?.errorText) ? fields[screenName + '.input.5']?.properties?.errorText : ''"
              :disabled="fields[screenName + '.input.5']?.properties?.disable"
              view="web"
              :class="$style['web-mb-address-form__col']"
            />
            <div :class="$style['web-mb-address-form__col']">
              <bpm-input
                v-model="house"
                :placeholder="fields[screenName + '.input.6']?.properties?.placeholder"
                :label="fields[screenName + '.input.6']?.properties?.label"
                :state="!fields[screenName + '.input.6']?.properties?.errorIndicator ? null : false"
                :error="(fields[screenName + '.input.6']?.properties?.errorIndicator && fields[screenName + '.input.6']?.properties?.errorText) ? fields[screenName + '.input.6']?.properties?.errorText : ''"
                :disabled="fields[screenName + '.input.6']?.properties?.disable || houseNumberCheck"
                view="web"
              />
              <bpm-checkbox
                v-model="houseNumberCheck"
                :value="String(houseNumberCheck)"
                :name="screenName + '.checkbox.7'"
                :label="fields[screenName + '.checkbox.7']?.properties?.text"
                :disabled="fields[screenName + '.checkbox.7']?.properties?.disable"
                :error="fields[screenName + '.checkbox.7']?.properties?.errorText"
                :class="$style['web-mb-address-form__form-checkbox']"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        :class="[
          $style['web-mb-address-form__col'],
          $style['web-mb-address-form__progress']
        ]"
      >
        <ProgressBarComponent
          :text="fields[screenName + '.webProgressBar.2']?.properties?.text || 'Filling progress'"
          :value="fields[screenName + '.webProgressBar.2']?.properties?.progress || 50"
        >
          <bpm-checkbox
            v-model="confirmCheck"
            :value="String(confirmCheck)"
            :name="screenName + '.checkbox.8'"
            :label="fields[screenName + '.checkbox.8']?.properties?.text"
            :disabled="fields[screenName + '.checkbox.8']?.properties?.disable"
            :error="fields[screenName + '.checkbox.8']?.properties?.errorText || confirmCheckError"
            :class="$style['web-mb-address-form__progress-checkbox']"
          />
          <!-- ButtonNextWidget -->
          <bpm-button
            fullwidth
            @click="validNextStep"
          >Continue</bpm-button>
        </ProgressBarComponent>
      </div>
    </div>
  </PanelComponent>
</template>

<script>
// checkbox - добавить state, т.к. может не быть текста ошибки

import PanelComponent from '@/components/app/ui/PanelComponent.vue'
import ProgressBarComponent from '@/components/app/ui/ProgressBarComponent.vue'
import BackButtonComponent from '@/components/app/ui/BackButtonComponent.vue'

import currentStepMixin from '@/mixins/currentStepMixin'

export default {
  name: 'AddressFormScreen',
  components: {
    PanelComponent,
    ProgressBarComponent,
    BackButtonComponent
  },
  mixins: [
    currentStepMixin
  ],
  data: () => {
    return {
      pin: '',
      locality: '',
      street: '',
      house: '',
      houseNumberCheck: false,
      confirmCheck: false,
      confirmCheckError: '' // todo: state
    }
  },
  computed: {
    currentNodeData () {
      return {
        [this.screenName + '.input.3']: this.pin,
        [this.screenName + '.input.4']: this.locality,
        [this.screenName + '.input.5']: this.street,
        [this.screenName + '.input.6']: this.house
        // checkbox - не требуется передавать
      }
    }
  },
  watch: {
    currentNodeData: {
      handler () {
        this.nodeData = this.currentNodeData
      },
      immediate: true
    },
    houseNumberCheck: {
      handler () {
        this.house = ''
      },
      immediate: true
    },
    confirmCheck: {
      handler () {
        this.confirmCheckError = ''
      }
    }
  },
  methods: {
    validNextStep () {
      if (!this.confirmCheck) {
        this.confirmCheckError = 'Required'
        return
      }

      this.nextStep()
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-address-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  &__panel {
    display: flex;
    flex-direction: column;
  }

  &__back-btn {
    padding-bottom: 1rem;
  }

  &__form {
    padding-top: 1.5rem;
  }

  &__inputs-wrap {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1rem;
  }

  &__form-checkbox {
    font-size: 0.75em;
    padding-top: 0.5rem;
  }

  &__progress {
    padding-top: 0.5rem;
  }

  &__progress-checkbox {
    font-size: 0.875em;
    padding-bottom: 0.75rem;
  }
}

@media (min-width: $min-md) {
  .web-mb-address-form {
    &__back-btn {
      padding-bottom: 1.5rem;
    }

    &__col {
      flex: 1;
    }

    &__inputs-wrap {
      flex-direction: row;
      padding-bottom: 1.5rem;
    }

    &__progress {
      flex: 0;
    }
  }
}

@media (min-width: $min-lg) {
  .web-mb-address-form {
    flex-direction: row;

    &__back-btn {
      padding-bottom: 2.5rem;
    }

    &__col {
      flex: 1;
    }

    &__form-wrap {
      flex: 2;
    }

    &__form {
      padding-top: 2rem;
      padding-right: 2rem;
    }

    &__progress {
      padding-top: 0;
    }
  }
}
</style>
