<template>
  <BannerPanelComponent>
    <div :class="$style['web-mb-banner__row']">
      <div
        :class="[
          $style['web-mb-banner__col'],
          $style['web-mb-banner__content']
        ]"
      >
        <div>
          <h2 :class="$style['web-mb-banner__header']">Header for your advertisement</h2>
          <p>Period up to 51 days: free, no hidden fees and overpayments</p>
        </div>
      </div>
      <div
        :class="[
          $style['web-mb-banner__col'],
          $style['web-mb-banner__img-wrap']
        ]"
      >
        <ImgComponent
          :class="$style['web-mb-banner__img']"
          :src="img"
          alt="Img"
        />
        <ImgComponent
          :class="$style['web-mb-banner__mob-img']"
          :src="img2"
          alt="Img"
        />
      </div>
    </div>
  </BannerPanelComponent>
</template>

<script>
import BannerPanelComponent from '@/components/ui/BannerPanelComponent.vue'
import ImgComponent from '@/components/ui/ImgComponent.vue'

import img from '@/assets/images/app/status-img.png'
import img2 from '@/assets/images/app/status-2-img.png'

export default {
  name: 'BannerComponent',
  components: {
    BannerPanelComponent,
    ImgComponent
  },
  data: () => {
    return {
      img,
      img2
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-banner {
  &__row {
    display: flex;
    flex-direction: column;
  }

  &__col {
    flex: 1;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
    margin: 1.5rem 1rem;
  }

  &__header {
    font-size: 2.222em;
    padding-bottom: 1rem;
    font-weight: 500;
  }

  &__img {
    display: none;
  }

  &__img-wrap {
    display: flex;
    justify-content: center;
    padding: 1rem 1rem 0 1rem;
  }

  @media (min-width: $min-md) {
    &__row {
      flex-direction: row;
    }

    &__content {
      gap: 3rem;
      text-align: initial;
      margin: 4rem 0 4rem 2rem;
    }
  }

  @media (min-width: $min-lg) {
    &__img {
      display: block;
    }

    &__mob-img {
      display: none;
    }

    &__content {
      margin: 3.75rem 0 3.75rem 5.5rem;
    }
  }
}
</style>
