<template>
  <PanelComponent
    :class="$style['web-mb-approval-amount-equal__panel']"
    :loading="isFetchingScreen"
  >    
    <div :class="$style['web-mb-approval-amount-equal']">
      <div
        :class="[
          $style['web-mb-approval-amount-equal__col'],
          $style['web-mb-approval-amount-equal__content'],
        ]"
      >
        <h2
          v-html="fields[screenName + '.block.1']?.properties?.text || 'Woohoo!<br> Application approved'"
        ></h2>
        <div>
          <p
            :class="$style['web-mb-approval-amount-equal__content-text']"
            v-html="fields[screenName + '.block.4']?.properties?.text || 'Your available credit balance is'"
          ></p>
          <p
            :class="$style['web-mb-approval-amount-equal__content-value']"
            v-html="fields[screenName + '.block.5']?.properties?.text || ''"
          ></p>
          <p
            :class="$style['web-mb-approval-amount-equal__content-description']"
            v-html="fields[screenName + '.block.6']?.properties?.text || ''"
          ></p>
        </div>
        <div :class="$style['web-mb-approval-amount-equal__content-download']">
          <div :class="$style['web-mb-approval-amount-equal__col']">
            <p
              :class="$style['web-mb-approval-amount-equal__download-text']"
              v-html="fields[screenName + '.block.7']?.properties?.text || ''"
            ></p>
            <div :class="$style['web-mb-approval-amount-equal__download-wrap']">
              <div :class="$style['web-mb-approval-amount-equal__download-links']">
                <ImgComponent
                  :src="googlePlayImg"
                  alt="Google Play"
                />
                <ImgComponent
                  :src="appStoreImg"
                  alt="App Store"
                />
              </div>
              <div :class="$style['web-mb-approval-amount-equal__download-links-mob']">
                <ImgComponent
                  :src="googlePlayImgMob"
                  alt="Google Play"
                />
                <ImgComponent
                  :src="appStoreImgMob"
                  alt="App Store"
                />
              </div>
            </div>
          </div>
          <div :class="[$style['web-mb-approval-amount-equal__col'], $style['web-mb-approval-amount-equal__img-mob-wrap']]">
            <ImgComponent
              :src="downloadImg"
              alt="Image"
            />
          </div>
        </div>
      </div>
      <div
        :class="[
          $style['web-mb-approval-amount-equal__col'],
          $style['web-mb-approval-amount-equal__img-wrap']
        ]"
      >
        <ImgComponent
          :src="downloadImg"
          alt="Image"
        />
      </div>
    </div>
  </PanelComponent>
</template>

<script>
import PanelComponent from '@/components/app/ui/PanelComponent.vue'
import ImgComponent from '@/components/ui/ImgComponent.vue'

import googlePlayImg from '@/assets/images/google-play-img.svg'
import appStoreImg from '@/assets/images/app-store-img.svg'
import googlePlayImgMob from '@/assets/images/app/google-play-img.svg'
import appStoreImgMob from '@/assets/images/app/app-store-img.svg'
import downloadImg from '@/assets/images/app/download-img.png'

import currentStepMixin from '@/mixins/currentStepMixin'

export default {
  name: 'ApprovalAmountEqualScreen',
  components: {
    PanelComponent,
    ImgComponent
  },
  mixins: [
    currentStepMixin
  ],
  data: () => {
    return {
      googlePlayImg,
      appStoreImg,
      googlePlayImgMob,
      appStoreImgMob,
      downloadImg
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-approval-amount-equal {
  display: flex;
  flex: 1;

  &__panel {
    display: flex;
    flex-direction: column;
  }

  &__back-btn {
    padding-bottom: 1rem;
  }

  &__col {
    flex: 1;
  }

  &__img-wrap {
    flex: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem; // ? 3rem

    &-text {
      font-weight: 600;
    }

    &-value {
      font-weight: 500;
      font-size: 2.5em; // ?
    }

    &-description {
      color: #707070;
      font-size: 0.875em;
    }

    &-list {
      padding-top: 1.5rem;
      list-style-position: inside;
    }

    &-list li {
      padding-bottom: 1rem;
    }
  }

  &__download-wrap,
  &__download-links,
  &__download-links-mob {
    display: flex;
    gap: 1rem;
  }

  &__download-links {
    display: none;
  }

  &__download-links-mob {
    flex-direction: column;
  }

  &__qr-code-img {
    display: none;
  }

  &__download-text {
    font-size: 0.875em;
    padding-bottom: 1rem;
  }

  &__content-download {
    display: flex;
  }

  &__img-mob-wrap {
    flex: 0;
  }

  @media (min-width: $min-md) {
    &__back-btn {
      padding-bottom: 1.5rem;
    }

    &__content {
      gap: 3rem;
    }

    &__download-links {
      display: flex;
      flex-direction: column;
    }

    &__download-links-mob {
      display: none;
    }

    &__qr-code-img {
      display: block;
    }

    &__img-mob-wrap {
      flex: 1;
    }
  }

  @media (min-width: $min-lg) {
    &__back-btn {
      padding-bottom: 2.5rem;
    }

    &__img-wrap {
      flex: 1;
    }

    &__img-mob-wrap {
      flex: 0;
    }
  }
}
</style>
