var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PanelComponent',{class:_vm.$style['web-mb-approval-amount-equal-or-less__panel'],attrs:{"loading":_vm.isFetchingScreen}},[_c('div',{class:_vm.$style['web-mb-approval-amount-equal-or-less']},[_c('div',{class:[
        _vm.$style['web-mb-approval-amount-equal-or-less__col'],
        _vm.$style['web-mb-approval-amount-equal-or-less__content'],
      ]},[_c('h2',{domProps:{"innerHTML":_vm._s(_vm.fields[_vm.screenName + '.block.1']?.properties?.text || 'Woohoo!<br> Application approved')}}),_c('div',[_c('p',{class:_vm.$style['web-mb-approval-amount-equal-or-less__content-text'],domProps:{"innerHTML":_vm._s(_vm.fields[_vm.screenName + '.block.4']?.properties?.text || 'Your available credit balance is')}}),_c('p',{class:_vm.$style['web-mb-approval-amount-equal-or-less__content-value'],domProps:{"innerHTML":_vm._s(_vm.fields[_vm.screenName + '.block.5']?.properties?.text || '')}}),_c('p',{class:_vm.$style['web-mb-approval-amount-equal-or-less__content-description'],domProps:{"innerHTML":_vm._s(_vm.fields[_vm.screenName + '.block.6']?.properties?.text || '')}})]),_c('div',{class:_vm.$style['web-mb-approval-amount-equal-or-less__content-download']},[_c('div',{class:_vm.$style['web-mb-approval-amount-equal-or-less__col']},[_c('p',{class:_vm.$style['web-mb-approval-amount-equal-or-less__download-text'],domProps:{"innerHTML":_vm._s(_vm.fields[_vm.screenName + '.block.7']?.properties?.text || '')}}),_c('div',{class:_vm.$style['web-mb-approval-amount-equal-or-less__download-wrap']},[_c('div',{class:_vm.$style['web-mb-approval-amount-equal-or-less__download-links']},[_c('ImgComponent',{attrs:{"src":_vm.googlePlayImg,"alt":"Google Play"}}),_c('ImgComponent',{attrs:{"src":_vm.appStoreImg,"alt":"App Store"}})],1),_c('div',{class:_vm.$style['web-mb-approval-amount-equal-or-less__download-links-mob']},[_c('ImgComponent',{attrs:{"src":_vm.googlePlayImgMob,"alt":"Google Play"}}),_c('ImgComponent',{attrs:{"src":_vm.appStoreImgMob,"alt":"App Store"}})],1)])]),_c('div',{class:[_vm.$style['web-mb-approval-amount-equal-or-less__col'], _vm.$style['web-mb-approval-amount-equal-or-less__img-mob-wrap']]},[_c('ImgComponent',{attrs:{"src":_vm.downloadImg,"alt":"Image"}})],1)])]),_c('div',{class:[
        _vm.$style['web-mb-approval-amount-equal-or-less__col'],
        _vm.$style['web-mb-approval-amount-equal-or-less__img-wrap']
      ]},[_c('ImgComponent',{attrs:{"src":_vm.downloadImg,"alt":"Image"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }