<template>
  <SectionComponent>
    <ContainerComponent>
      <component
        :is="currentScreen.component"
        :screen-name="currentScreen?.name"
        :screen-fields="currentScreen?.nodeFields"
        :screen-widgets="currentScreen?.widgets"
        :is-fetching-screen="isFetchingScreen"
        @nextStep="nextStep"
        @prevStep="prevStep"
      />
    </ContainerComponent>
  </SectionComponent>
</template>

<script>
import SectionComponent from '@/components/ui/SectionComponent.vue'
import ContainerComponent from '@/components/ui/ContainerComponent.vue'

import stepsLogicMixin from '@/mixins/stepsMixin'

export default {
  name: 'WebMobBank',
  components: {
    SectionComponent,
    ContainerComponent,
  },
  mixins: [
    stepsLogicMixin
  ]
}
</script>
