<template>
  <footer :class="$style['web-mb-footer']">
    <ContainerComponent>
      <div :class="$style['web-mb-footer__content']">
        <div :class="$style['web-mb-footer__row']">
          <div :class="$style['web-mb-footer__logo-wrap']">
            <ImgComponent
              :src="logoImg"
              alt="Logo"
              :imgStyle="{ width: '85px', height: 'auto' }"
            />
            <div :class="$style['web-mb-footer__mb-email']">chat@vivafinance.in</div>
          </div>
          <nav :class="$style['web-mb-footer__nav']">
            <div
              v-for="route in routes"
              :key="route.name"
            >
              <a :href="route.path">{{ route.title }}</a>
            </div>
            <div>
              <a :href="($route.path === '/' || $route.path === '/products' ? '' : '/') + '#faq'">FAQ</a>
            </div>
          </nav>
        </div>
        <div :class="$style['web-mb-footer__row']">
          <div :class="$style['web-mb-footer__email']">
            <div :class="$style['web-mb-footer__email-name']">Email</div>
            <div :class="$style['web-mb-footer__email-value']">chat@vivafinance.in</div>
          </div>
          <div :class="$style['web-mb-footer__download-wrap']">
            <ImgComponent
              :src="googlePlayImg"
              :imgStyle="{ width: 'auto', height: '56px' }"
              alt="Google Play"
            />
            <ImgComponent
              :src="appStoreImg"
              :imgStyle="{ width: 'auto', height: '56px' }"
              alt="App Store"
            />
            <ImgComponent
              :class="$style['web-mb-footer__qr-code-img']"
              :src="qrCodeImg"
              :imgStyle="{ width: '56px', height: '56px' }"
              alt="QR Code"
            />
          </div>
        </div>
        
        <div :class="$style['web-mb-footer__info']">
          <div :class="$style['web-mb-footer__info-links']">
            <div :class="$style['web-mb-footer__info-data']">
              <a href="#">Terms and Conditions</a>
              <a href="#">Privacy Policy</a>
              <a href="#">Fair Practices Code</a>
            </div>
            <div :class="$style['web-mb-footer__social']">
              <ImgComponent
                :src="instagramIcon"
                :imgStyle="{ width: '24px', height: '24px' }"
                alt="instagram"
              />
              <ImgComponent
                :src="telegramIcon"
                :imgStyle="{ width: '24px', height: '24px' }"
                alt="telegram"
              />
              <ImgComponent
                :src="facebookIcon"
                :imgStyle="{ width: '24px', height: '24px' }"
                alt="facebook"
              />
              <ImgComponent
                :src="twitterIcon"
                :imgStyle="{ width: '24px', height: '24px' }"
                alt="twitter"
              />
              <ImgComponent
                :src="youtubeIcon"
                :imgStyle="{ width: '24px', height: '24px' }"
                alt="youtube"
              />
            </div>
          </div>
          <div :class="$style['web-mb-footer__divider']"></div>
          <div :class="$style['web-mb-footer__info-about']">
            <div :class="$style['web-mb-footer__info-about-col-first']">
              <div>
                <div>NBFC Partner</div>
                <div>FincFriends Private Limited</div>
              </div>
              <div>
                <div>CIN: U36737286KA74834</div>
                <div>©2023 VIVA MONEY SOLUTION PVT LTD</div>
              </div>
            </div>
            <div :class="$style['web-mb-footer__info-about-col-second']">VIVA MONEY SOLUTION PVT LTD is a part of DFI group, a leading financial services provider in global market.</div>
          </div>
        </div>
      </div>
    </ContainerComponent>
  </footer>
</template>

<script>
import ContainerComponent from '@/components/ui/ContainerComponent.vue'
import ImgComponent from '@/components/ui/ImgComponent.vue'

// images
import logoImg from '@/assets/images/logo-img.svg'
import googlePlayImg from '@/assets/images/google-play-img.svg'
import appStoreImg from '@/assets/images/app-store-img.svg'
import qrCodeImg from '@/assets/images/qr-code-img.svg'

// icons
import instagramIcon from '@/assets/icons/social/instagram-icon.svg'
import telegramIcon from '@/assets/icons/social/telegram-icon.svg'
import facebookIcon from '@/assets/icons/social/facebook-icon.svg'
import twitterIcon from '@/assets/icons/social/twitter-icon.svg'
import youtubeIcon from '@/assets/icons/social/youtube-icon.svg'

import { routes } from '@/router'

export default {
  name: 'FooterComponent',
  components: {
    ContainerComponent,
    ImgComponent
  },
  data: () => {
    return {
      // FAQ (home: FAQ якорь)
      // Blog ?
      routes,
      logoImg,
      googlePlayImg,
      appStoreImg,
      qrCodeImg,
      instagramIcon,
      telegramIcon,
      facebookIcon,
      twitterIcon,
      youtubeIcon
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-footer {
  margin-top: auto;
  background-color: #E9ECF8;
  padding: 2rem 1rem;
  border-radius: 1.5rem 1.5rem 0 0;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__divider {
    width: 100%;
    height: 0.800px;
    background: #96ADB8;
    opacity: 0.5;
  }

  &__row {
    display: flex;
    flex-direction: column;
  }

  &__logo-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;
  }

  &__mb-email {
    font-weight: 600;
  }

  &__nav {
    display: flex;
    justify-content: space-between;
  }

  &__nav a:link,
  &__nav a:visited,
  &__nav a:hover,
  &__nav a:focus,
  &__nav a:active {
    color: #B51ACE;
    text-decoration: none;
    font-weight: 600;
    transition: color .15s ease-in-out;
  }

  &__nav a:hover,
  &__nav a:focus,
  &__nav a:active {
    color: #0E1A22;
  }

  &__email {
    display: none;

    &-name {
      color: #5A717C;
      font-size: 0.667em;
    }

    &-value {
      font-weight: 600;
      font-size: 1.111em;
    }
  }

  &__download-wrap {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__qr-code-img {
    display: none;
  }

  &__info {
    display: flex;
    flex-direction: column;
    // align-items: center;
    font-size: 0.75em;
    gap: 1.5rem;
    color: #5A717C;

    a:link,
    a:visited,
    a:hover,
    a:focus,
    a:active {
      color: #5A717C;
    }
  }

  &__info-data {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__social {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  &__info-links {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    a:link,
    a:visited,
    a:hover,
    a:focus,
    a:active {
      text-decoration: underline;
      transition: color .15s ease-in-out;
    }

    a:hover,
    a:focus,
    a:active {
      color: #B51ACE;
    }
  }

  &__info-about {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;

    &-col {
      &-first {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }
  }

  @media (min-width: $min-md) {
    padding: 2rem 2.75rem;

    &__row {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &__logo-wrap {
      padding: 0;
    }

    &__mb-email {
      display: none;
    }
    
    &__nav {
      gap: 1.5rem;
    }

    &__email {
      display: block;
    }

    &__social {
      width: auto;
      gap: 2rem;
    }

    &__info-about {
      text-align: initial;

      &-col {
        &-first {
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
  }

  @media (min-width: $min-lg) {
    padding: 2rem 7rem;

    &__content {
      gap: 2rem;
    }

    &__qr-code-img {
      display: block;
    }

    &__info-data {
      flex-wrap: nowrap;
    }

    &__info-links {
      flex-direction: row;
      justify-content: space-between;
      flex: 1;
    }

    &__info-about {
      flex-direction: row;
      justify-content: space-between;

      &-col {
        &-first {
          display: flex;
          flex: 2;
          gap: 2rem;
          justify-content: start;
        }

        &-second {
          flex: 1;
          text-align: right;
        }
      }
    }
  }
}
</style>
