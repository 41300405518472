<template>
  <div :class="$style['web-mb-section-panel']">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SectionPanelComponent'
}
</script>

<style lang="scss" module>
.web-mb-section-panel {
  background-color: $section-panel-background-color;
  border-radius: $section-panel-border-radius;
  padding: $section-panel-y-indent $section-panel-x-indent;

  @media (min-width: $min-md) {
    border-radius: $section-panel-border-radius-md;
    padding: $section-panel-y-indent-md $section-panel-x-indent-md;
  }

  @media (min-width: $min-lg) {
    padding: $section-panel-y-indent-lg $section-panel-x-indent-lg;
  }
}
</style>
