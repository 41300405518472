var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SectionComponent',[_c('ContainerComponent',[_c('BannerPanelComponent',[_c('div',{class:_vm.$style['web-mb-banner-section__row']},[_c('div',{class:[
            _vm.$style['web-mb-banner-section__col'],
            _vm.$style['web-mb-banner-section__content']
          ]},[_c('div',[_c('h2',{class:_vm.$style['web-mb-banner-section__header']},[_vm._v("Download VIVA")]),_c('p',{class:_vm.$style['web-mb-banner-section__header-descr']},[_vm._v("Your Personal Loan App")]),_c('p',[_vm._v("Instantly withdraw money from your credit limit of up to ₹2,00,000! No paperwork, ever")])]),_c('div',{class:_vm.$style['web-mb-banner-section__download-wrap']},[_c('div',{class:_vm.$style['web-mb-banner-section__qr-code-wrap']},[_c('ImgComponent',{attrs:{"src":_vm.qrCodeImg,"alt":"QR Code"}})],1),_c('div',{class:_vm.$style['web-mb-banner-section__download-links-wrap']},[_c('ImgComponent',{attrs:{"src":_vm.googlePlayImg,"imgStyle":{ width: 'auto', height: '42px' },"alt":"Google Play"}}),_c('ImgComponent',{attrs:{"src":_vm.appStoreImg,"imgStyle":{ width: 'auto', height: '42px' },"alt":"App Store"}})],1)])]),_c('div',{class:[
            _vm.$style['web-mb-banner-section__col'],
            _vm.$style['web-mb-banner-section__img-wrap']
          ]},[_c('ImgComponent',{attrs:{"src":_vm.img,"alt":"Img"}})],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }