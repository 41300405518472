var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SectionComponent',{attrs:{"is-wider":""}},[_c('ContainerComponent',{attrs:{"is-wider":""}},[_c('SectionHeaderComponent',{attrs:{"is-wider":""}},[_vm._v("Steps to apply for Credit Line")]),_c('VueSlickCarousel',_vm._b({},'VueSlickCarousel',_vm.carouselSettings,false),_vm._l((_vm.slides),function(slide){return _c('div',{key:slide.id},[_c('SectionPanelComponent',{class:_vm.$style['web-mb-slides-section__panel']},[_c('div',{class:_vm.$style['web-mb-slides-section__row']},[_c('div',{class:[
              _vm.$style['web-mb-slides-section__col'],
              _vm.$style['web-mb-slides-section__img-wrap']
            ]},[_c('ImgComponent',{attrs:{"src":slide.img,"alt":"Image"}})],1),_c('div',{class:[
                _vm.$style['web-mb-slides-section__col'],
                _vm.$style['web-mb-slides-section__content-wrap']
              ]},[_c('div',{class:_vm.$style['web-mb-slides-section__content']},[_c('h2',[_vm._v(_vm._s(slide.title))]),_c('p',[_vm._v(_vm._s(slide.text))])]),_c('div',{class:_vm.$style['web-mb-slides-section__nums']},_vm._l((_vm.slides.length),function(n){return _c('div',{key:n,class:[{ [_vm.$style['web-mb-slides-section__current-slide']]: n === slide.id }]},[_vm._v(_vm._s(n))])}),0)])])])],1)}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }