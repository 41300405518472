var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PanelComponent',{class:_vm.$style['web-mb-documents-form__panel'],attrs:{"loading":_vm.isFetchingScreen}},[_c('div',{class:_vm.$style['web-mb-documents-form__back-btn']},[_c('BackButtonComponent',{on:{"click":_vm.prevStep}})],1),_c('div',{class:_vm.$style['web-mb-documents-form']},[_c('div',{class:[
        _vm.$style['web-mb-documents-form__col'],
        _vm.$style['web-mb-documents-form__form-wrap']
      ]},[_c('h2',{style:(_vm.fields[_vm.screenName + '.block.1']?.inlineStyle),domProps:{"innerHTML":_vm._s(_vm.fields[_vm.screenName + '.block.1']?.properties?.text || 'Identification Details')}}),_c('div',{class:_vm.$style['web-mb-documents-form__form']},[_c('h3',{class:_vm.$style['web-mb-documents-form__form-header'],style:(_vm.fields[_vm.screenName + '.block.3']?.inlineStyle),domProps:{"innerHTML":_vm._s(_vm.fields[_vm.screenName + '.block.3']?.properties?.text || 'Documents')}}),_c('div',{class:_vm.$style['web-mb-documents-form__inputs-wrap']},[_c('bpm-input',{attrs:{"placeholder":_vm.fields[_vm.screenName + '.input.4']?.properties?.placeholder,"label":_vm.fields[_vm.screenName + '.input.4']?.properties?.label,"state":!_vm.fields[_vm.screenName + '.input.4']?.properties?.errorIndicator ? null : false,"error":(_vm.fields[_vm.screenName + '.input.4']?.properties?.errorIndicator && _vm.fields[_vm.screenName + '.input.4']?.properties?.errorText) ? _vm.fields[_vm.screenName + '.input.4']?.properties?.errorText : '',"disabled":_vm.fields[_vm.screenName + '.input.4']?.properties?.disable,"view":"web"},model:{value:(_vm.aadhaarID),callback:function ($$v) {_vm.aadhaarID=$$v},expression:"aadhaarID"}}),_c('bpm-input',{attrs:{"placeholder":_vm.fields[_vm.screenName + '.input.5']?.properties?.placeholder,"label":_vm.fields[_vm.screenName + '.input.5']?.properties?.label,"state":!_vm.fields[_vm.screenName + '.input.5']?.properties?.errorIndicator ? null : false,"error":(_vm.fields[_vm.screenName + '.input.5']?.properties?.errorIndicator && _vm.fields[_vm.screenName + '.input.5']?.properties?.errorText) ? _vm.fields[_vm.screenName + '.input.5']?.properties?.errorText : '',"disabled":_vm.fields[_vm.screenName + '.input.5']?.properties?.disable,"view":"web"},model:{value:(_vm.panID),callback:function ($$v) {_vm.panID=$$v},expression:"panID"}})],1)])]),_c('div',{class:[
        _vm.$style['web-mb-documents-form__col'],
        _vm.$style['web-mb-documents-form__progress']
      ]},[_c('ProgressBarComponent',{attrs:{"text":_vm.fields[_vm.screenName + '.webProgressBar.2']?.properties?.text || 'Filling progress',"value":_vm.fields[_vm.screenName + '.webProgressBar.2']?.properties?.progress || 60}},[_c('bpm-button',{attrs:{"fullwidth":""},on:{"click":_vm.nextStep}},[_vm._v("Continue")])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }