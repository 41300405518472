var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PanelComponent',{class:_vm.$style['web-mb-identification__panel'],attrs:{"loading":_vm.isFetchingScreen}},[_c('div',{class:_vm.$style['web-mb-identification__back-btn']},[_c('BackButtonComponent',{on:{"click":_vm.prevStep}})],1),_c('div',{class:_vm.$style['web-mb-identification']},[_c('div',{class:[
        _vm.$style['web-mb-identification__col'],
        _vm.$style['web-mb-identification__form-wrap']
      ]},[_c('div',{class:[
          _vm.$style['web-mb-identification__col'],
          _vm.$style['web-mb-identification__form']
        ]},[_c('h2',{style:(_vm.fields[_vm.screenName + '.block.1']?.inlineStyle),domProps:{"innerHTML":_vm._s(_vm.fields[_vm.screenName + '.block.1']?.properties?.text || 'Verify your phone number')}}),_c('p',{class:_vm.$style['web-mb-identification__text-content'],style:(_vm.fields[_vm.screenName + '.block.4']?.inlineStyle),domProps:{"innerHTML":_vm._s(_vm.fields[_vm.screenName + '.block.4']?.properties?.text || 'This is a one-time OTP verification. Promise!')}}),_c('div',{class:_vm.$style['web-mb-identification__input-wrap']},[_c('bpm-input',{attrs:{"placeholder":_vm.fields[_vm.screenName + '.input.5']?.properties?.placeholder,"label":_vm.fields[_vm.screenName + '.input.5']?.properties?.label,"state":!_vm.fields[_vm.screenName + '.input.5']?.properties?.errorIndicator ? null : false,"error":(_vm.fields[_vm.screenName + '.input.5']?.properties?.errorIndicator && _vm.fields[_vm.screenName + '.input.5']?.properties?.errorText) ? _vm.fields[_vm.screenName + '.input.5']?.properties?.errorText : '',"disabled":_vm.fields[_vm.screenName + '.input.5']?.properties?.disable,"view":"web"},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})],1)]),_c('div',{class:_vm.$style['web-mb-identification__col']})]),_c('div',{class:_vm.$style['web-mb-identification__col']}),_c('div',{class:_vm.$style['web-mb-identification__col']},[_c('ProgressBarComponent',{attrs:{"text":_vm.fields[_vm.screenName + '.webProgressBar.3']?.properties?.text || 'Filling progress',"value":_vm.fields[_vm.screenName + '.webProgressBar.3']?.properties?.progress || 5}},[_c('bpm-button',{attrs:{"fullwidth":""},on:{"click":_vm.nextStep}},[_vm._v("Continue")]),_c('p',{class:_vm.$style['web-mb-identification__agreement'],style:(_vm.fields[_vm.screenName + '.block.7']?.inlineStyle),domProps:{"innerHTML":_vm._s(_vm.fields[_vm.screenName + '.block.7']?.properties?.text || '')}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }