<template>
  <div id="app" :class="$style['web-mb-app']">
    <HeaderComponent />
    <router-view></router-view>
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from '@/components/site/HeaderComponent.vue'
import FooterComponent from '@/components/site/FooterComponent.vue'

export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent
  },
}
</script>

<style lang="scss" module>
.web-mb-app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  overflow-x: hidden;

  // tablet
  @media (min-width: $min-md) {
    font-size: 1.125rem;
  }

  // desktop
  @media (min-width: $min-lg) {
  }
}
</style>
