var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BannerPanelComponent',[_c('div',{class:_vm.$style['web-mb-banner__row']},[_c('div',{class:[
        _vm.$style['web-mb-banner__col'],
        _vm.$style['web-mb-banner__content']
      ]},[_c('div',[_c('h2',{class:_vm.$style['web-mb-banner__header']},[_vm._v("Header for your advertisement")]),_c('p',[_vm._v("Period up to 51 days: free, no hidden fees and overpayments")])])]),_c('div',{class:[
        _vm.$style['web-mb-banner__col'],
        _vm.$style['web-mb-banner__img-wrap']
      ]},[_c('ImgComponent',{class:_vm.$style['web-mb-banner__img'],attrs:{"src":_vm.img,"alt":"Img"}}),_c('ImgComponent',{class:_vm.$style['web-mb-banner__mob-img'],attrs:{"src":_vm.img2,"alt":"Img"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }