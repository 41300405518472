var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PanelComponent',{class:_vm.$style['web-mb-income-details-student-unemployed__panel'],attrs:{"loading":_vm.isFetchingScreen}},[_c('div',{class:_vm.$style['web-mb-income-details-student-unemployed__back-btn']},[_c('BackButtonComponent',{on:{"click":_vm.prevStep}})],1),_c('div',{class:_vm.$style['web-mb-income-details-student-unemployed']},[_c('div',{class:[
        _vm.$style['web-mb-income-details-student-unemployed__col'],
        _vm.$style['web-mb-income-details-student-unemployed__form-wrap']
      ]},[_c('h2',{style:(_vm.fields[_vm.screenName + '.block.1']?.inlineStyle),domProps:{"innerHTML":_vm._s(_vm.fields[_vm.screenName + '.block.1']?.properties?.text || 'Income Details')}}),_c('div',{class:_vm.$style['web-mb-income-details-student-unemployed__form']},[_c('div',{class:_vm.$style['web-mb-income-details-student-unemployed__inputs-wrap']},[_c('bpm-input',{class:_vm.$style['web-mb-income-details-student-unemployed__col'],attrs:{"placeholder":_vm.fields[_vm.screenName + '.input.3']?.properties?.placeholder,"label":_vm.fields[_vm.screenName + '.input.3']?.properties?.label,"state":!_vm.fields[_vm.screenName + '.input.3']?.properties?.errorIndicator ? null : false,"error":(_vm.fields[_vm.screenName + '.input.3']?.properties?.errorIndicator && _vm.fields[_vm.screenName + '.input.3']?.properties?.errorText) ? _vm.fields[_vm.screenName + '.input.3']?.properties?.errorText : '',"disabled":_vm.fields[_vm.screenName + '.input.3']?.properties?.disable,"view":"web"},model:{value:(_vm.gross),callback:function ($$v) {_vm.gross=$$v},expression:"gross"}})],1)])]),_c('div',{class:[
        _vm.$style['web-mb-income-details-student-unemployed__col'],
        _vm.$style['web-mb-income-details-student-unemployed__progress']
      ]},[_c('ProgressBarComponent',{attrs:{"text":_vm.fields[_vm.screenName + '.progressBar.2']?.properties?.text || 'Filling progress',"value":_vm.fields[_vm.screenName + '.progressBar.2']?.properties?.progress || 90}},[_c('bpm-checkbox',{class:_vm.$style['web-mb-income-details-student-unemployed__progress-checkbox'],attrs:{"value":String(_vm.confirmCheck),"name":_vm.screenName + '.checkbox.4',"label":"I confirm that the monthly incomeof my Household is more than ₹25,000","disabled":_vm.fields[_vm.screenName + '.checkbox.4']?.properties?.disable,"error":_vm.fields[_vm.screenName + '.checkbox.4']?.properties?.errorText || _vm.confirmCheckError},model:{value:(_vm.confirmCheck),callback:function ($$v) {_vm.confirmCheck=$$v},expression:"confirmCheck"}}),_c('bpm-button',{attrs:{"fullwidth":""},on:{"click":_vm.validNextStep}},[_vm._v("Continue")])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }