<template>
  <PanelComponent
    :loading="isFetchingScreen"
  >
    <div :class="$style['web-mb-status']">
      <div :class="$style['web-mb-status__wrap']">
        <div :class="$style['web-mb-status__content']">
          <h2
            :style="fields[screenName + '.block.1']?.inlineStyle"
            v-html="fields[screenName + '.block.1']?.properties?.text || 'We’re verifying your application'"
          ></h2>
          <p
            :class="$style['web-mb-status__text-content']"
            :style="fields[screenName + '.block.2']?.inlineStyle"
            v-html="fields[screenName + '.block.2']?.properties?.text || 'This usually takes up to 5 minutes. Grab a snack, but don\'t go back or close the page'"
          ></p>
        </div>
        <div :class="$style['web-mb-status__mob-banner-wrap']">
          <BannerComponent />
        </div>
        <div :class="$style['web-mb-status__loader']">
          <div
            :class="$style['web-mb-status__text-loader']"
            :style="fields[screenName + '.block.3']?.inlineStyle"
            v-html="fields[screenName + '.block.3']?.properties?.text || 'Hang on, it\'s taking us longer than usual'"
          ></div>
          <bpm-timer
            mode="large"
          />
        </div>
      </div>
      <div :class="$style['web-mb-status__banner-wrap']">
        <BannerComponent />
      </div>
    </div>
  </PanelComponent>
</template>

<script>
import PanelComponent from '@/components/app/ui/PanelComponent.vue'
import BannerComponent from '@/components/app/ui/BannerComponent.vue'

import currentStepMixin from '@/mixins/currentStepMixin'

export default {
  name: 'StatusScreen',
  components: {
    PanelComponent,
    BannerComponent
  },
  mixins: [
    currentStepMixin
  ],
  data: () => {
    return {
    }
  },
  computed: {
    timer () {
      return this.fields[this.screenName + '.timer.4']?.properties?.endTime
    }
  },
}
</script>

<style lang="scss" module>
.web-mb-status {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__wrap {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__text-content {
    color: #707070;
  }

  &__loader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
  }

  &__text-loader {
    font-size: 0.75em;
  }

  &__banner-wrap {
    display: none;
  }
}

@media (min-width: $min-lg) {
  .web-mb-status {
    &__wrap {
      display: flex;
      flex-direction: row;
    }

    &__content {
      flex: 3;
    }

    &__loader {
      flex: 1;
    }

    &__banner-wrap {
      display: block;
    }

    &__mob-banner-wrap {
      display: none;
    }
  }
}
</style>
