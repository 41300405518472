var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:_vm.$style['web-mb-product-section']},[_c('PagePanelComponent',{class:_vm.$style['web-mb-product-section__panel-' + _vm.product.id]},[_c('ContainerComponent',{class:_vm.$style['web-mb-product-section__container']},[_c('div',{class:[
          _vm.$style['web-mb-product-section__row'],
          { [_vm.$style['web-mb-product-section__row--reverse']]: _vm.product.reverse }
        ]},[_c('div',{class:_vm.$style['web-mb-product-section__col']},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.product.title)}}),_c('ul',{class:_vm.$style['web-mb-product-section__list']},_vm._l((_vm.product.list),function(item,index){return _c('li',{key:index,domProps:{"innerHTML":_vm._s(item)}})}),0)]),_c('div',{class:[
            _vm.$style['web-mb-product-section__col'],
            _vm.$style['web-mb-product-section__img-wrap']
          ]})])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }