<template>
  <div
    :class="[
      $style['bpm-file-input'],
      { [$style[`bpm-file-input--${size}`]]: size !== null },
      { [$style['bpm-file-input--fullwidth']]: fullwidth }
    ]"
  >
    <div
      v-if="isSelectedFiles"
      :class="$style['bpm-file-input__files']"
    >
      <div
        v-for="file in files"
        :class="$style['bpm-file-input__file-wrap']"
        :key="file.id"
      >
        <div :class="$style['bpm-file-input__file-icon-wrap']">
          <div :class="$style['bpm-file-input__file-icon']"></div>
          <div :class="$style['bpm-file-input__file']">
            <span>{{ file.name }}</span>
            <span>{{ file.size }}</span>
          </div>
        </div>
        <span
          :class="$style['bpm-file-input__file-remove']"
          @click="removeFile(file)"
        >Delete</span>
      </div>
    </div>
    <label
      :for="id"
      :class="[
        $style['bpm-file-input__label'],
        { [$style[`bpm-file-input__label--is-selected`]]: isSelectedFiles },
        { [$style[`bpm-file-input__label--${size}`]]: size !== null && size === 'x-small' },
        { [$style[`bpm-file-input__label--disabled`]]: disabled },
        { [$style[`bpm-file-input__label--is-photo`]]: mode && mode === 'photo' }
      ]"
    >
      <input
        :accept="accept"
        :disabled="disabled"
        :class="$style['bpm-file-input__input']"
        :id="id"
        :multiple="multiple"
        type="file"
        @change="handleChange"
      />
      <template v-if="mode && mode === 'photo'">
        <div :class="$style['bpm-file-input__label-icon-photo']"></div>
        <div :class="$style['bpm-file-input__label-text-photo']">{{ label }}</div>
      </template>
      <template v-else>
        <div v-if="icon && !isSelectedFiles" :class="$style['bpm-file-input__label-icon']"></div>
        <div>{{ isSelectedFiles ? secondLabel : label }}</div>
      </template>
    </label>
  </div>
</template>

<script>
import uid from '../../utils/uid'
import { UploadableFile } from '../../utils/file'

export default {
  name: 'BpmFileInput',
  props: {
    accept: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fullwidth: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    secondLabel: {
      type: String,
      default: '+ Add more'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null // x-small, small, large
    },
    mode: {
      type: String,
      default: 'deafult' // deafult, photo
    }
  },
  data: () => {
    return {
      files: []
    }
  },
  computed: {
    id () {
      return uid()
    },
    isSelectedFiles () {
      return this.files?.length !== 0
    }
  },
  methods: {
    addFiles (newFiles) {
      let newUploadableFiles = [...newFiles]
        .map((file) => new UploadableFile(file))
        .filter((file) => !this.fileExists(file.id))
      this.files = this.files.concat(newUploadableFiles)
    },
    fileExists (otherId) {
      return this.files.some(({ id }) => id === otherId)
    },
    handleChange (e) {
      this.addFiles(e.target.files)

      this.$emit('select-files', this.files)
    },
    removeFile (file) {
      const index = this.files.indexOf(file)
      if (index > -1) this.files.splice(index, 1)
      this.$emit('select-files', this.files)
    }
  }
}
</script>

<style lang="scss" module src="./styles/BpmFileInput.module.scss">
</style>
