<template>
  <span :class="$style['web-mb-back-button']" @click="$emit('click')">
    <span :class="$style['web-mb-back-button__icon']"></span> 
    <span>Back</span>
  </span>
</template>

<script>
export default {
  name: 'BackButtonComponent'
}
</script>

<style lang="scss" module>
.web-mb-back-button {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: #b51ace;
  font-weight: 500;

  &__icon {
    display: block;
    height: 1.5rem;
    width: 1.5rem;
    background-image: url(../../../assets/icons/btn-arrow-icon.svg);
    background-repeat: no-repeat;
    background-position: center center;
  }
}
</style>
