import axios from 'axios'

// todo: вынести
const HOST = 'https://08dd0291.paylate.ru'

// токен - периодически вызывать
export async function fetchCsrf (data = null, headers = null) {
  return axios.request({
    url: `${HOST}/api/v1/bpm/token/csrf`,
    method: 'GET',
    data,
    headers,
    responseType: 'json',
    responseEncoding: 'utf8',
    // withCredentials: true
  })
}

// 1 запрос - получение токена
// 2 и след запросы - получение/отправка содержимого
export async function fetchStep (data = null, headers = null) {
  return axios.request({
    url: `${HOST}/api/v1/bpm/step`,
    method: 'POST',
    data,
    headers,
    responseType: 'json',
    responseEncoding: 'utf8',
    // withCredentials: true
  })
}
