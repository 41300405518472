<template>
  <main :class="$style['web-mb-reach-page']">
    <!-- REACH US -->
    <section :class="$style['web-mb-reach-page__section']">
      <PagePanelComponent>
        <ContainerComponent>
          <div :class="$style['web-mb-reach-page__row']">
            <div
              :class="[
                $style['web-mb-reach-page__col'],
                $style['web-mb-reach-page__content-wrap']
              ]"
            >
              <p
                v-for="item in content"
                :key="item.id"
              >
                <span
                  :class="$style['web-mb-reach-page__content-name']"
                >{{ item.name }}</span>
                <span
                  :class="$style['web-mb-reach-page__content-value']"
                >{{ item.value }}</span>
              </p>
            </div>
            <div
              :class="[
                $style['web-mb-reach-page__col'],
                $style['web-mb-reach-page__img-wrap']
              ]"
            >
              <ImgComponent
                :src="mainImg"
                alt="Reach Us"
              />
            </div>
          </div>
        </ContainerComponent>
      </PagePanelComponent>
    </section>

    <BannerSection />
  </main>
</template>

<script>
import PagePanelComponent from '@/components/ui/PagePanelComponent.vue'
import ContainerComponent from '@/components/ui/ContainerComponent.vue'
import ImgComponent from '@/components/ui/ImgComponent.vue'
import BannerSection from '@/components/site/sections/BannerSection.vue'

import data from '@/data/reach.json'
import mainImg from '@/assets/images/reach/main-img.png'

export default {
  name: 'ReachPage',
  components: {
    PagePanelComponent,
    ContainerComponent,
    ImgComponent,
    BannerSection
  },
  data: () => {
    return {
      content: data?.content,
      mainImg
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-reach-page {
  &__section {
    margin-bottom: 1.5rem;
  }

  &__row {
    display: flex;
  }

  &__col {
    flex: 1;
  }

  &__content {
    &-wrap {
      display: flex;
      flex-direction: column;
      gap: 1em;
    }

    &-name, &-value {
      display: block;
    }
    
    &-name {
      font-weight: 600;
    }
  }

  &__img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0;
  }

  @media (min-width: $min-md) {
    &__img-wrap {
      flex: 1;
    }
  }
}
</style>
