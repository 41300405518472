<template>
  <main>
    <ProductSection
      v-for="product in products"
      :key="product.id"
      :product="product"
    />

    <WebMobBank />

    <SlidesSection />

    <!-- <AboutSection /> -->

    <ReviewsSection />

    <BannerSection />

    <FaqSection />
  </main>
</template>

<script>
import ProductSection from '@/components/site/sections/ProductSection.vue'
import WebMobBank from '@/components/app/WebMobBank.vue'
import SlidesSection from '@/components/site/sections/SlidesSection.vue'
// import AboutSection from '@/components/site/sections/AboutSection.vue'
import ReviewsSection from '@/components/site/sections/ReviewsSection.vue'
import BannerSection from '@/components/site/sections/BannerSection.vue'
import FaqSection from '@/components/site/sections/FaqSection.vue'

import img1 from '@/assets/images/products/product-1-img.png'
import img2 from '@/assets/images/products/product-2-img.png'
import img3 from '@/assets/images/products/product-3-img.png'
import img4 from '@/assets/images/products/product-4-img.png'

export default {
  name: 'ProductsPage',
  components: {
    ProductSection,
    WebMobBank,
    SlidesSection,
    // AboutSection,
    ReviewsSection,
    BannerSection,
    FaqSection
  },
  data: () => {
    return {
      // json + img loader
      products: [
        {
          id: 1,
          title: '<h2>Benefits of using VIVA- Credit Line Loan</h2>',
          list: [
            'Credit limit:<br><span class="color-text-dark-first" style="font-weight: 500">Remove from ₹5,000 to ₹200,000</span>',
            'Processing fee (One-time processing fee):<br><span class="color-text-dark-first" style="font-weight: 500">From ₹500 up to 3% of the approved credit limit</span>',
            'Grace period:<br><span class="color-text-dark-first" style="font-weight: 500">Maximum 51 days if you withdraw on the 1st of the month (else, 20 days after the end of the withdrawal\'s billing month)</span>'
          ],
          img: img1,
          reverse: false
        },
        {
          id: 2,
          title: '<h2>How you repay</h2>',
          list: [
            '<span style="color: #E9ECF8">If Repaid within the Grace Period:<span><br><span style="font-weight: 500">0% per annum interest charged</span>',
            'Otherwise, you can choose between 5, 10, and 20 month long EMI payments. In this case, the following conditions will apply:',
            '<span style="color: #E9ECF8">Interest rate:<span><br><span style="font-weight: 500">As low as 3-3.3% per EMI</span>',
            '<span style="color: #E9ECF8">Repayment term:<span><br><span style="font-weight: 500">- 5 months: No extra charge<br>- 10 months: ₹999<br>- 20 months: ₹1499</span>',
            '<span style="color: #E9ECF8">Early repayment fee:<span><br><span style="font-weight: 500">4% on the early repayment amount<br>Note: Minimum repayment amount is ₹10,000</span>'
          ],
          img: img2,
          reverse: true
        },
        {
          id: 3,
          title: '<h2>Minimal Documents Required</h2><span class="color-text-minor-first" style="font-size: 1em">100% digital and completely secure</span>',
          list: [
            'Identity Proof (PAN card)',
            'Address Proof (Aadhaar number)',
            'Photograph (Selfie)'
          ],
          img: img3,
          reverse: false
        },
        {
          id: 4,
          title: '<h2>Eligibility Criteria</h2>',
          list: [
            'You must be an Indian Citizen between 21 and 64 years of age',
            'Your monthly income must be ₹15,000 or more.<br>Your monthly household income must be ₹25,000 or more',
            'You must be earning an official income either as a full-time, part-time, or self-employed professional'
          ],
          img: img4,
          reverse: true
        }
      ]
    }
  }
}
</script>
