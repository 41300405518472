import formatNodeFields from '@/utils/formatNodeFields'
// import getFormattedDefaultNodeDataToSend from '@/utils/getFormattedDefaultNodeDataToSend'

export default {
  props: {
    screenName: {
      type: String,
      default: () => ''
    },
    screenFields: {
      type: Array,
      default: () => []
    },
    screenWidgets: {
      type: Array,
      default: () => []
    },
    isFetchingScreen: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      nodeData: {}
    }
  },
  computed: {
    fields () {
      return formatNodeFields(this.screenFields)
    }
  },
  // watch: {
  //   fields (newValue, oldValue) {
  //     this.nodeData = getFormattedDefaultNodeDataToSend(this.screenFields)
  //   }
  // },
  methods: {
    nextStep () {
      this.$emit('nextStep', this.nodeData)
    },
    prevStep () {
      this.$emit('prevStep', this.nodeData)
    },
    getArrData (arrData, arrLocalData) {
      return arrData.length !== 0 ? arrData : arrLocalData
    }
  },
  // mounted () {
  //   console.log('data', this.screenFields)
  // }
}
