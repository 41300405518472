<template>
  <div :class="$style['web-mb-progress-bar']">
    <div :class="$style['web-mb-progress-bar__progress']">
      <div :class="$style['web-mb-progress-bar__progress-value']">
        <div v-html="text"></div>
        <div>{{ value }}%</div>
      </div>
      <bpm-progress
        :max="100"
        :value="progressValue"
        backgroundColor="#FFFFFF"
      />
    </div>

    <div :class="$style['web-mb-progress-bar__slot']">
      <slot>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBarComponent',
  props: {
    text: {
      type: String,
      default: 'Filling progress'
    },
    value: {
      type: [String, Number],
      default: 0
    }
  },
  computed: {
    progressValue () {
      return Number(this.value)
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #F5F7FB;
  border-radius: 1rem;
  padding: 1rem 1rem;
  height: 100%;

  &__progress-value {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    margin-bottom: -0.5rem;
  }

  &__slot {
    padding-top: 1.5rem;
  }

  @media (min-width: $min-md) {
    flex-direction: row;
    padding: 1rem 1.5rem;
    gap: 4rem;

    &__progress, &__slot {
      flex: 1;
    }

    &__slot {
      padding-top: 0;
    }
  }

  @media (min-width: $min-lg) {
    flex-direction: column;
    padding: 3rem 1.5rem 2rem 1.5rem;

    &__progress, &__slot {
      flex: 0;
    }
  }
}
</style>
