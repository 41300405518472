<template>
  <PanelComponent
    :class="$style['web-mb-personal-data-form__panel']"
    :loading="isFetchingScreen"
  >
    <!-- ButtonArrowWidget -->
    <div :class="$style['web-mb-personal-data-form__back-btn']">
      <BackButtonComponent @click="prevStep" />
    </div>

    <div :class="$style['web-mb-personal-data-form']">
      <div
        :class="[
          $style['web-mb-personal-data-form__col'],
          $style['web-mb-personal-data-form__form-wrap']
        ]"
      >
        <h2
          :style="fields[screenName + '.block.1']?.inlineStyle"
          v-html="fields[screenName + '.block.1']?.properties?.text || 'Tell us about yourself'"
        ></h2>

        <div :class="$style['web-mb-personal-data-form__form']">
          <h3
            :class="$style['web-mb-personal-data-form__form-header']"
            :style="fields[screenName + '.block.3']?.inlineStyle"
            v-html="fields[screenName + '.block.3']?.properties?.text || 'Personal info'"
          ></h3>
          <div :class="$style['web-mb-personal-data-form__inputs-wrap']">
            <bpm-input
              v-model="firstName"
              :placeholder="fields[screenName + '.input.4']?.properties?.placeholder"
              :label="fields[screenName + '.input.4']?.properties?.label"
              :state="!fields[screenName + '.input.4']?.properties?.errorIndicator ? null : false"
              :error="(fields[screenName + '.input.4']?.properties?.errorIndicator && fields[screenName + '.input.4']?.properties?.errorText) ? fields[screenName + '.input.4']?.properties?.errorText : ''"
              :disabled="fields[screenName + '.input.4']?.properties?.disable"
              view="web"
            />
            <bpm-input
              v-model="lastName"
              :placeholder="fields[screenName + '.input.5']?.properties?.placeholder"
              :label="fields[screenName + '.input.5']?.properties?.label"
              :state="!fields[screenName + '.input.5']?.properties?.errorIndicator ? null : false"
              :error="(fields[screenName + '.input.5']?.properties?.errorIndicator && fields[screenName + '.input.5']?.properties?.errorText) ? fields[screenName + '.input.5']?.properties?.errorText : ''"
              :disabled="fields[screenName + '.input.5']?.properties?.disable"
              view="web"
            />
            <bpm-input
              v-model="dateOfBirth"
              :placeholder="fields[screenName + '.input.6']?.properties?.placeholder"
              :label="fields[screenName + '.input.6']?.properties?.label"
              :state="!fields[screenName + '.input.6']?.properties?.errorIndicator ? null : false"
              :error="(fields[screenName + '.input.6']?.properties?.errorIndicator && fields[screenName + '.input.6']?.properties?.errorText) ? fields[screenName + '.input.6']?.properties?.errorText : ''"
              :disabled="fields[screenName + '.input.6']?.properties?.disable"
              view="web"
              type="date"
            />
          </div>

          <h3
            :class="$style['web-mb-personal-data-form__form-header']"
            :style="fields[screenName + '.block.7']?.inlineStyle"
            v-html="fields[screenName + '.block.7']?.properties?.text || 'Marital status'"
          ></h3>
          <div :class="$style['web-mb-personal-data-form__radios']">
            <div :class="$style['web-mb-personal-data-form__radios-wrap']">
              <bpm-radio
                v-for="(radioValue, index) in statusData"
                v-model="status"
                :key="radioValue + index"
                :value="radioValue"
                :label="radioValue"
                :name="screenName + '.radiobutton.8'"
                bubble
              />
            </div>
            <div
              v-if="fields[screenName + '.radiobutton.8']?.properties?.errorIndicator"
              v-html="fields[screenName + '.radiobutton.8']?.properties?.errorText"
            ></div>
          </div>

          <h3
            :class="$style['web-mb-personal-data-form__form-header']"
            :style="fields[screenName + '.block.9']?.inlineStyle"
            v-html="fields[screenName + '.block.9']?.properties?.text || 'Number of family dependents'"
          ></h3>
          <div :class="$style['web-mb-personal-data-form__radios']">
            <div :class="$style['web-mb-personal-data-form__radios-wrap']">
              <bpm-radio
                v-for="(radioValue, index) in dependentsData"
                v-model="dependents"
                :key="radioValue + index"
                :value="radioValue"
                :label="radioValue"
                :name="screenName + '.radiobutton.10'"
                bubble
              />
            </div>
            <div
              v-if="fields[screenName + '.radiobutton.10']?.properties?.errorIndicator"
              v-html="fields[screenName + '.radiobutton.10']?.properties?.errorText"
            ></div>
          </div>

          <h3
            :class="$style['web-mb-personal-data-form__form-header']"
            :style="fields[screenName + '.block.11']?.inlineStyle"
            v-html="fields[screenName + '.block.11']?.properties?.text || 'Religion'"
          ></h3>
          <div :class="$style['web-mb-personal-data-form__radios']">
            <div :class="$style['web-mb-personal-data-form__radios-wrap']">
              <bpm-radio
                v-for="(radioValue, index) in religionData"
                v-model="religion"
                :key="radioValue + index"
                :value="radioValue"
                :label="radioValue"
                :name="screenName + '.radiobutton.12'"
                bubble
              />
            </div>
            <div
              v-if="fields[screenName + '.radiobutton.12']?.properties?.errorIndicator"
              v-html="fields[screenName + '.radiobutton.12']?.properties?.errorText"
            ></div>
          </div>
        </div>
      </div>

      <div
        :class="[
          $style['web-mb-personal-data-form__col'],
          $style['web-mb-personal-data-form__progress']
        ]"
      >
        <ProgressBarComponent
          :text="fields[screenName + '.webProgressBar.2']?.properties?.text || 'Filling progress'"
          :value="fields[screenName + '.webProgressBar.2']?.properties?.progress || 40"
        >
          <!-- ButtonNextWidget -->
          <bpm-button
            fullwidth
            @click="nextStep"
          >Continue</bpm-button>
        </ProgressBarComponent>
      </div>
    </div>
  </PanelComponent>
</template>

<script>
// у экрана есть виджет календаря - пока не реализован

// todo:
// требуется доработка bpm-input, bpm-radio
// errorIndicator - реализовать логику, уточнить по значениям
// значение по умолчанию в полях - уточнить где брать

import PanelComponent from '@/components/app/ui/PanelComponent.vue'
import ProgressBarComponent from '@/components/app/ui/ProgressBarComponent.vue'
import BackButtonComponent from '@/components/app/ui/BackButtonComponent.vue'

import currentStepMixin from '@/mixins/currentStepMixin'

export default {
  name: 'PersonalDataFormScreen',
  components: {
    PanelComponent,
    ProgressBarComponent,
    BackButtonComponent
  },
  mixins: [
    currentStepMixin
  ],
  data: () => {
    return {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      status: null,
      dependents: null,
      religion: null,
      // локальные альтернативы данных (можно вынести и хранить в json)
      statusLocalData: [
        'Single',
        'Married',
        'Divorced',
        'Widowed'
      ],
      dependentsLocalData: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5+'
      ],
      religionLocalData: [
        'Hinduism',
        'Islam',
        'Christianity',
        'Sikhism',
        'Buddhism',
        'Jainism',
        'Other'
      ]
    }
  },
  computed: {
    currentNodeData () {
      return {
        [this.screenName + '.input.4']: this.firstName,
        [this.screenName + '.input.5']: this.lastName,
        [this.screenName + '.input.6']: this.dateOfBirth,
        [this.screenName + '.radiobutton.8']: this.status ?? '',
        [this.screenName + '.radiobutton.10']: this.dependents ?? '',
        [this.screenName + '.radiobutton.12']: this.religion ?? ''
      }
    },
    statusData () {
      return this.getArrData(this.fields[this.screenName + '.radiobutton.8']?.listData, this.statusLocalData)
    },
    dependentsData () {
      return this.getArrData(this.fields[this.screenName + '.radiobutton.10']?.listData, this.dependentsLocalData)
    },
    religionData () {
      return this.getArrData(this.fields[this.screenName + '.radiobutton.12']?.listData, this.religionLocalData)
    }
  },
  watch: {
    currentNodeData: {
      handler () {
        this.nodeData = this.currentNodeData
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-personal-data-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  &__panel {
    display: flex;
    flex-direction: column;
  }

  &__back-btn {
    padding-bottom: 1rem;
  }

  &__form {
    padding-top: 1.5rem;
  }

  &__form-header {
    font-size: 1rem;
    color: #5A717C;
    font-weight: 600;
    padding-bottom: 0.75rem;
  }

  &__inputs-wrap {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  &__radios,
  &__inputs-wrap {
    padding-bottom: 1.5rem;
  }

  &__radios-wrap {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;

    // todo
    & > div {
      width: auto;
    }
  }

  &__progress {
    padding-top: 0.5rem;
  }
}

@media (min-width: $min-md) {
  .web-mb-personal-data-form {
    &__back-btn {
      padding-bottom: 1.5rem;
    }

    &__col {
      flex: 1;
    }

    &__inputs-wrap {
      grid-template-columns: 1fr 1fr;
    }

    &__progress {
      flex: 0;
    }
  }
}

@media (min-width: $min-lg) {
  .web-mb-personal-data-form {
    flex-direction: row;

    &__back-btn {
      padding-bottom: 2.5rem;
    }

    &__col {
      flex: 1;
    }

    &__form-wrap {
      flex: 2;
    }

    &__form {
      padding-top: 2rem;
      padding-right: 2rem;
    }

    &__progress {
      padding-top: 0;
    }

    &__inputs-wrap {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
</style>
