<template>
  <PanelComponent
    :class="$style['web-mb-authentication-third-party-otp-check-second__panel']"
    :loading="isFetchingScreen"
  >
    <!-- ButtonArrowWidget -->
    <div :class="$style['web-mb-authentication-third-party-otp-check-second__back-btn']">
      <BackButtonComponent @click="prevStep" />
    </div>

    <div :class="$style['web-mb-authentication-third-party-otp-check-second']">
      <div
        :class="[
          $style['web-mb-authentication-third-party-otp-check-second__col'],
          $style['web-mb-authentication-third-party-otp-check-second__form-wrap']
        ]"
      >
        <div
          :class="[
            $style['web-mb-authentication-third-party-otp-check-second__col'],
            $style['web-mb-authentication-third-party-otp-check-second__form']
          ]"
        >
          <h2
            :style="fields[screenName + '.block.1']?.inlineStyle"
            v-html="fields[screenName + '.block.1']?.properties?.text || 'Enter the 6-digit OTP'"
          ></h2>

          <div :class="$style['web-mb-authentication-third-party-otp-check-second__otp-wrap']">
            <p
              :style="fields[screenName + '.block.3']?.inlineStyle"
              v-html="fields[screenName + '.block.3']?.properties?.text"
              :class="$style['web-mb-authentication-third-party-otp-check-second__text-content']"
            ></p>

            <bpm-web-otp-input
              :number="Number(fields[screenName + '.input.OTP.4']?.properties?.numberSymbols)"
              :state="!fields[screenName + '.input.OTP.4']?.properties?.errorIndicator ? null : false"
              :error="(fields[screenName + '.input.OTP.4']?.properties?.errorIndicator && fields[screenName + '.input.OTP.4']?.properties?.errorText) ? fields[screenName + '.input.OTP.4']?.properties?.errorText : ''"
              @change="handleChangeOtp"
            />

            <div :class="$style['web-mb-authentication-third-party-otp-check-second__timer-wrap']">
              <div
                v-if="!endOfTimer"
                :class="$style['web-mb-authentication-third-party-otp-check-second__timer']"
              >
                <span
                  v-html="fields[screenName + '.timer.5']?.properties?.text"
                  :class="$style['web-mb-authentication-third-party-otp-check-second__otp-text']"
                ></span>
                <bpm-timer
                  :text="timer"
                />
              </div>

              <div
                v-if="endOfTimer"
                :class="$style['web-mb-authentication-third-party-otp-check-second__refresh-otp']"
              >
                <span
                  v-html="fields[screenName + '.button.main.6']?.properties?.text"
                  :class="$style['web-mb-authentication-third-party-otp-check-second__otp-refresh-text']"
                ></span>

                <span
                  v-html="fields[screenName + '.timer.5']?.properties?.endText"
                  :class="$style['web-mb-authentication-third-party-otp-check-second__otp-refresh-text']"
                  @click="handleClickOtp(fields[screenName + '.button.main.6']?.properties?.action)"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div :class="$style['web-mb-authentication-third-party-otp-check-second__col']">
        <ProgressBarComponent
          :text="fields[screenName + '.webProgressBar.2']?.properties?.text || 'Filling progress'"
          :value="fields[screenName + '.webProgressBar.2']?.properties?.progress || 70"
        >
          <!-- ButtonNextWidget -->
          <bpm-button
            fullwidth
            @click="nextStep"
          >Continue</bpm-button>
        </ProgressBarComponent>
      </div>
    </div>
  </PanelComponent>
</template>

<script>
import PanelComponent from '@/components/app/ui/PanelComponent.vue'
import ProgressBarComponent from '@/components/app/ui/ProgressBarComponent.vue'
import BackButtonComponent from '@/components/app/ui/BackButtonComponent.vue'

import currentStepMixin from '@/mixins/currentStepMixin'

export default {
  name: 'AuthenticationThirdPartyOtpCheckSecondScreen',
  components: {
    PanelComponent,
    ProgressBarComponent,
    BackButtonComponent
  },
  mixins: [
    currentStepMixin
  ],
  data: () => {
    return {
      otp: '',
      endOfTimer: false
    }
  },
  computed: {
    currentNodeData () {
      return {
        [this.screenName + '.input.OTP.4']: this.otp
      }
    },
    timer () {
      return this.fields[this.screenName + '.timer.5']?.properties?.endTime
    }
  },
  watch: {
    currentNodeData: {
      handler () {
        this.nodeData = this.currentNodeData
      },
      immediate: true
    }
  },
  methods: {
    handleChangeOtp (value) {
      this.otp = value
    },
    handleClickOtp (action) {
      // этот action передать выше в запрос
      console.log(action)
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-authentication-third-party-otp-check-second {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  &__panel {
    display: flex;
    flex-direction: column;
  }

  &__back-btn {
    padding-bottom: 1rem;
  }

  &__text-content {
    padding-bottom: 1rem;
  }

  &__otp-wrap {
    padding-top: 1.5rem;
  }

  &__otp-text,
  &__otp-refresh-text {
    font-size: 0.875em;
  }

  &__timer-wrap {
    padding-top: 1rem;
  }

  &__timer {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  &__refresh-otp {
    display: inline-flex;
    flex-direction: column;
  }
}

@media (min-width: $min-md) {
  .web-mb-authentication-third-party-otp-check-second {
    &__back-btn {
      padding-bottom: 1.5rem;
    }

    &__form-wrap {
      display: flex;
      flex-direction: row;
    }

    &__otp-wrap {
      padding-top: 3rem;
    }
  }
}

@media (min-width: $min-lg) {
  .web-mb-authentication-third-party-otp-check-second {
    flex-direction: row;

    &__back-btn {
      padding-bottom: 2.5rem;
    }

    &__col {
      flex: 1;
    }

    &__form-wrap {
      flex-direction: column;
      flex: 2;
    }
  }
}
</style>
