import Vue from 'vue'
import App from './App.vue'
import router from './router'

import uikitBpm from '../public/ui-kit/uikit-bpm.umd.js'

import "@/styles/main.scss"

Vue.config.productionTip = false

Vue.use(uikitBpm)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
