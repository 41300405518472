<template>
  <PanelComponent
    :class="$style['web-mb-documents-form__panel']"
    :loading="isFetchingScreen"
  >
    <!-- ButtonArrowWidget -->
    <div :class="$style['web-mb-documents-form__back-btn']">
      <BackButtonComponent @click="prevStep" />
    </div>

    <div :class="$style['web-mb-documents-form']">
      <div
        :class="[
          $style['web-mb-documents-form__col'],
          $style['web-mb-documents-form__form-wrap']
        ]"
      >
        <h2
          :style="fields[screenName + '.block.1']?.inlineStyle"
          v-html="fields[screenName + '.block.1']?.properties?.text || 'Identification Details'"
        ></h2>

        <div :class="$style['web-mb-documents-form__form']">
          <h3
            :class="$style['web-mb-documents-form__form-header']"
            :style="fields[screenName + '.block.3']?.inlineStyle"
            v-html="fields[screenName + '.block.3']?.properties?.text || 'Documents'"
          ></h3>
          <div :class="$style['web-mb-documents-form__inputs-wrap']">
            <bpm-input
              v-model="aadhaarID"
              :placeholder="fields[screenName + '.input.4']?.properties?.placeholder"
              :label="fields[screenName + '.input.4']?.properties?.label"
              :state="!fields[screenName + '.input.4']?.properties?.errorIndicator ? null : false"
              :error="(fields[screenName + '.input.4']?.properties?.errorIndicator && fields[screenName + '.input.4']?.properties?.errorText) ? fields[screenName + '.input.4']?.properties?.errorText : ''"
              :disabled="fields[screenName + '.input.4']?.properties?.disable"
              view="web"
            />
            <bpm-input
              v-model="panID"
              :placeholder="fields[screenName + '.input.5']?.properties?.placeholder"
              :label="fields[screenName + '.input.5']?.properties?.label"
              :state="!fields[screenName + '.input.5']?.properties?.errorIndicator ? null : false"
              :error="(fields[screenName + '.input.5']?.properties?.errorIndicator && fields[screenName + '.input.5']?.properties?.errorText) ? fields[screenName + '.input.5']?.properties?.errorText : ''"
              :disabled="fields[screenName + '.input.5']?.properties?.disable"
              view="web"
            />
          </div>
        </div>
      </div>

      <div
        :class="[
          $style['web-mb-documents-form__col'],
          $style['web-mb-documents-form__progress']
        ]"
      >
        <ProgressBarComponent
          :text="fields[screenName + '.webProgressBar.2']?.properties?.text || 'Filling progress'"
          :value="fields[screenName + '.webProgressBar.2']?.properties?.progress || 60"
        >
          <!-- ButtonNextWidget -->
          <bpm-button
            fullwidth
            @click="nextStep"
          >Continue</bpm-button>
        </ProgressBarComponent>
      </div>
    </div>
  </PanelComponent>
</template>

<script>
import PanelComponent from '@/components/app/ui/PanelComponent.vue'
import ProgressBarComponent from '@/components/app/ui/ProgressBarComponent.vue'
import BackButtonComponent from '@/components/app/ui/BackButtonComponent.vue'

import currentStepMixin from '@/mixins/currentStepMixin'

export default {
  name: 'DocumentsFormScreen',
  components: {
    PanelComponent,
    ProgressBarComponent,
    BackButtonComponent
  },
  mixins: [
    currentStepMixin
  ],
  data: () => {
    return {
      aadhaarID: '',
      panID: ''
    }
  },
  computed: {
    currentNodeData () {
      return {
        [this.screenName + '.input.4']: this.aadhaarID,
        [this.screenName + '.input.5']: this.panID
      }
    }
  },
  watch: {
    currentNodeData: {
      handler () {
        this.nodeData = this.currentNodeData
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-documents-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  &__panel {
    display: flex;
    flex-direction: column;
  }

  &__back-btn {
    padding-bottom: 1rem;
  }

  &__form {
    padding-top: 1.5rem;
  }

  &__form-header {
    font-size: 1rem;
    color: #5A717C;
    font-weight: 600;
    padding-bottom: 0.75rem;
  }

  &__inputs-wrap {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1rem;
  }

  &__progress {
    padding-top: 0.5rem;
  }
}

@media (min-width: $min-md) {
  .web-mb-documents-form {
    &__back-btn {
      padding-bottom: 1.5rem;
    }

    &__col {
      flex: 1;
    }

    &__inputs-wrap {
      flex-direction: row;
      padding-bottom: 1.5rem;
    }

    &__progress {
      flex: 0;
    }
  }
}

@media (min-width: $min-lg) {
  .web-mb-documents-form {
    flex-direction: row;

    &__back-btn {
      padding-bottom: 2.5rem;
    }

    &__col {
      flex: 1;
    }

    &__form-wrap {
      flex: 2;
    }

    &__form {
      padding-top: 2rem;
      padding-right: 2rem;
    }

    &__progress {
      padding-top: 0;
    }
  }
}
</style>
