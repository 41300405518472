<template>
  <PanelComponent
    :class="$style['web-mb-identification__panel']"
    :loading="isFetchingScreen"
  >
    <!-- ButtonArrowWidget -->
    <div :class="$style['web-mb-identification__back-btn']">
      <BackButtonComponent @click="prevStep" />
    </div>

    <div :class="$style['web-mb-identification']">
      <div
        :class="[
          $style['web-mb-identification__col'],
          $style['web-mb-identification__form-wrap']
        ]"
      >
        <div
          :class="[
            $style['web-mb-identification__col'],
            $style['web-mb-identification__form']
          ]"
        >
          <h2
            :style="fields[screenName + '.block.1']?.inlineStyle"
            v-html="fields[screenName + '.block.1']?.properties?.text || 'Verify your phone number'"
          ></h2>
          <p
            :class="$style['web-mb-identification__text-content']"
            :style="fields[screenName + '.block.4']?.inlineStyle"
            v-html="fields[screenName + '.block.4']?.properties?.text || 'This is a one-time OTP verification. Promise!'"
          ></p>

          <div :class="$style['web-mb-identification__input-wrap']">
            <bpm-input
              v-model="phoneNumber"
              :placeholder="fields[screenName + '.input.5']?.properties?.placeholder"
              :label="fields[screenName + '.input.5']?.properties?.label"
              :state="!fields[screenName + '.input.5']?.properties?.errorIndicator ? null : false"
              :error="(fields[screenName + '.input.5']?.properties?.errorIndicator && fields[screenName + '.input.5']?.properties?.errorText) ? fields[screenName + '.input.5']?.properties?.errorText : ''"
              :disabled="fields[screenName + '.input.5']?.properties?.disable"
              view="web"
            />
          </div>
        </div>
        <div :class="$style['web-mb-identification__col']"></div>
      </div>

      <div :class="$style['web-mb-identification__col']"></div>

      <div :class="$style['web-mb-identification__col']">
        <ProgressBarComponent
          :text="fields[screenName + '.webProgressBar.3']?.properties?.text || 'Filling progress'"
          :value="fields[screenName + '.webProgressBar.3']?.properties?.progress || 5"
        >
          <!-- ButtonNextWidget -->
          <bpm-button
            fullwidth
            @click="nextStep"
          >Continue</bpm-button>
          <p
            :class="$style['web-mb-identification__agreement']"
            :style="fields[screenName + '.block.7']?.inlineStyle"
            v-html="fields[screenName + '.block.7']?.properties?.text || ''"
          ></p>
        </ProgressBarComponent>
      </div>
    </div>
  </PanelComponent>
</template>

<script>
// todo:
// механизм, который парсит и преобразует в ссылки
// <span class='url' href='https://example.com'>

import PanelComponent from '@/components/app/ui/PanelComponent.vue'
import ProgressBarComponent from '@/components/app/ui/ProgressBarComponent.vue'
import BackButtonComponent from '@/components/app/ui/BackButtonComponent.vue'

import currentStepMixin from '@/mixins/currentStepMixin'

export default {
  name: 'IdentificationScreen',
  components: {
    PanelComponent,
    ProgressBarComponent,
    BackButtonComponent
  },
  mixins: [
    currentStepMixin
  ],
  data: () => {
    return {
      phoneNumber: ''
    }
  },
  computed: {
    currentNodeData () {
      return {
        [this.screenName + '.input.5']: this.phoneNumber
      }
    }
  },
  watch: {
    currentNodeData: {
      handler () {
        this.nodeData = this.currentNodeData
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-identification {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  &__panel {
    display: flex;
    flex-direction: column;
  }

  &__back-btn {
    padding-bottom: 1rem;
  }

  &__text-content {
    color: #707070;
  }

  &__input-wrap {
    padding-top: 1.5rem;
  }

  &__agreement {
    text-align: center;
    font-size: 0.75em;
    padding-top: 0.5rem;
  }
}

@media (min-width: $min-md) {
  .web-mb-identification {
    &__back-btn {
      padding-bottom: 1.5rem;
    }

    &__form-wrap {
      display: flex;
      flex-direction: row;
    }

    &__form-wrap &__col {
      flex: 1;
    }

    &__form-wrap &__form {
      flex: 2;
    }

    &__input-wrap {
      padding-top: 3rem;
    }
  }
}

@media (min-width: $min-lg) {
  .web-mb-identification {
    flex-direction: row;

    &__back-btn {
      padding-bottom: 2.5rem;
    }

    &__col {
      flex: 1;
    }

    &__form-wrap {
      flex-direction: column;
    }
  }
}
</style>
