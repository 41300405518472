<template>
  <div
    :class="[
      $style['bpm-select'],
      { [$style[`bpm-select--${size}`]]: size !== null },
      { [$style['bpm-select--web']]: this.view === 'web' }
    ]"
  >
    <bpm-input
      v-model="selectedOption"
      :description="description"
      :disabled="disabled"
      :error="error"
      :id="id"
      :isOptions="showOptions"
      :isSelect="true"
      :label="label"
      :placeholder="placeholder"
      :required="required"
      :size="size"
      :state="state"
      :name="name"
      :readonly="!combobox"
      :view="view"
      @input="handleInput"
      @click="handleClick"
    />
    <div
      :class="[
        $style['bpm-select__options'],
        { [$style[`bpm-select__options--show`]]: showOptions }
      ]"
    >
      <div
        v-for="option in currentOptions"
        :key="option.id"
        :class="[
          $style['bpm-select__option'],
          { [$style['bpm-select__option--disabled']]: option.disabled }
        ]"
        @click="selectOption(option)"
      >{{ option.label }}</div>
    </div>
  </div>
</template>

<script>
import BpmInput from '../BpmInput'
import uid from '../../utils/uid'

// outside click

export default {
  name: 'BpmSelect',
  model: {
    prop: 'value',
    event: 'change'
  },
  components: {
    BpmInput
  },
  props: {
    combobox: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    options: {
      type: Array, // [id (генерировать), label, value, disabled]
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },    
    size: {
      type: String,
      default: null // x-small, small, large
    },
    state: {
      type: Boolean, // null, true, false
      default: null
    },
    value: {
      type: String,
      default: null
    },
    view: {
      type: String,
      default: 'mobile' // mobile, web
    }
  },
  watch: {
    value: {
      handler (value) {
        this.selectedOption = this.options.filter(option => option.value === value)?.[0]?.label
      },
      immediate: true
    }
  },
  data: () => {
    return {
      showOptions: false,
      selectedOption: null,
      filteredOptions: []
    }
  },
  computed: {
    id () {
      return uid()
    },
    currentOptions () {
      return this.filteredOptions.length !== 0 ? this.filteredOptions : this.options
    }
  },
  methods: {
    handleInput (value) {
      // доработка поиска
      this.filteredOptions = this.options.filter(option => option.label === value)
    },
    handleClick () {
      this.toggleOptions()
    },
    selectOption (option) {
      if (option.disabled) return

      this.$emit('change', option.value)
      this.toggleOptions()
    },
    toggleOptions() {
      this.showOptions = !this.showOptions
    }
  }
}
</script>

<style lang="scss" module src="./styles/BpmSelect.module.scss">
</style>
