<template>
  <PanelComponent
    :class="$style['web-mb-income-details-student-unemployed__panel']"
    :loading="isFetchingScreen"
  >
    <!-- ButtonArrowWidget -->
    <div :class="$style['web-mb-income-details-student-unemployed__back-btn']">
      <BackButtonComponent @click="prevStep" />
    </div>

    <div :class="$style['web-mb-income-details-student-unemployed']">
      <div
        :class="[
          $style['web-mb-income-details-student-unemployed__col'],
          $style['web-mb-income-details-student-unemployed__form-wrap']
        ]"
      >
        <h2
          :style="fields[screenName + '.block.1']?.inlineStyle"
          v-html="fields[screenName + '.block.1']?.properties?.text || 'Income Details'"
        ></h2>

        <div :class="$style['web-mb-income-details-student-unemployed__form']">
          <div :class="$style['web-mb-income-details-student-unemployed__inputs-wrap']">
            <bpm-input
              v-model="gross"
              :placeholder="fields[screenName + '.input.3']?.properties?.placeholder"
              :label="fields[screenName + '.input.3']?.properties?.label"
              :state="!fields[screenName + '.input.3']?.properties?.errorIndicator ? null : false"
              :error="(fields[screenName + '.input.3']?.properties?.errorIndicator && fields[screenName + '.input.3']?.properties?.errorText) ? fields[screenName + '.input.3']?.properties?.errorText : ''"
              :disabled="fields[screenName + '.input.3']?.properties?.disable"
              view="web"
              :class="$style['web-mb-income-details-student-unemployed__col']"
            />
          </div>
        </div>
      </div>

      <div
        :class="[
          $style['web-mb-income-details-student-unemployed__col'],
          $style['web-mb-income-details-student-unemployed__progress']
        ]"
      >
        <ProgressBarComponent
          :text="fields[screenName + '.progressBar.2']?.properties?.text || 'Filling progress'"
          :value="fields[screenName + '.progressBar.2']?.properties?.progress || 90"
        >
          <!-- text сломан -->
          <!-- label -->
          <!-- {{ fields[screenName + '.checkbox.4']?.properties?.text }} -->
          <bpm-checkbox
            v-model="confirmCheck"
            :value="String(confirmCheck)"
            :name="screenName + '.checkbox.4'"
            label="I confirm that the monthly incomeof my Household is more than ₹25,000"
            :disabled="fields[screenName + '.checkbox.4']?.properties?.disable"
            :error="fields[screenName + '.checkbox.4']?.properties?.errorText || confirmCheckError"
            :class="$style['web-mb-income-details-student-unemployed__progress-checkbox']"
          />
          <!-- ButtonNextWidget -->
          <bpm-button
            fullwidth
            @click="validNextStep"
          >Continue</bpm-button>
        </ProgressBarComponent>
      </div>
    </div>
  </PanelComponent>
</template>

<script>
import PanelComponent from '@/components/app/ui/PanelComponent.vue'
import ProgressBarComponent from '@/components/app/ui/ProgressBarComponent.vue'
import BackButtonComponent from '@/components/app/ui/BackButtonComponent.vue'

import currentStepMixin from '@/mixins/currentStepMixin'

export default {
  name: 'IncomeDetailsStudentUnemployedScreen',
  components: {
    PanelComponent,
    ProgressBarComponent,
    BackButtonComponent
  },
  mixins: [
    currentStepMixin
  ],
  data: () => {
    return {
      gross: '',
      confirmCheck: false,
      confirmCheckError: '' // todo: state
    }
  },
  computed: {
    currentNodeData () {
      return {
        [this.screenName + '.input.3']: this.gross,
        // checkbox - не требуется передавать
      }
    }
  },
  watch: {
    currentNodeData: {
      handler () {
        this.nodeData = this.currentNodeData
      },
      immediate: true
    },
    confirmCheck: {
      handler () {
        this.confirmCheckError = ''
      }
    }
  },
  methods: {
    validNextStep () {
      if (!this.confirmCheck) {
        this.confirmCheckError = 'Required'
        return
      }

      this.nextStep()
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-income-details-student-unemployed {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  &__panel {
    display: flex;
    flex-direction: column;
  }

  &__back-btn {
    padding-bottom: 1rem;
  }

  &__form {
    padding-top: 1.5rem;
  }

  &__inputs-wrap {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1rem;
  }

  &__form-checkbox {
    font-size: 0.75em;
    padding-top: 0.5rem;
  }

  &__progress {
    padding-top: 0.5rem;
  }

  &__progress-checkbox {
    font-size: 0.875em;
    padding-bottom: 0.75rem;
  }
}

@media (min-width: $min-md) {
  .web-mb-income-details-student-unemployed {
    &__back-btn {
      padding-bottom: 1.5rem;
    }

    &__col {
      flex: 1;
    }

    &__inputs-wrap {
      flex-direction: row;
      padding-bottom: 1.5rem;
    }

    &__progress {
      flex: 0;
    }
  }
}

@media (min-width: $min-lg) {
  .web-mb-income-details-student-unemployed {
    flex-direction: row;

    &__back-btn {
      padding-bottom: 2.5rem;
    }

    &__col {
      flex: 1;
    }

    &__form-wrap {
      flex: 2;
    }

    &__form {
      padding-top: 2rem;
      padding-right: 2rem;
    }

    &__progress {
      padding-top: 0;
    }
  }
}
</style>
