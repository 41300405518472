<template>
  <div :class="[$style['bpm-timer'], { [$style[`bpm-timer--${size}`]]: size !== null }]">
    <div
      :class="[
        $style['bpm-timer__loader'],
        { [$style[`bpm-timer__loader--${mode}`]]: mode !== null }
      ]"
    ></div>
    <template v-if="text && text !== ''">
      <div v-if="mode === 'small'" :class="$style['bpm-timer__outer-text']">
        {{ text }}
      </div>

      <div v-else-if="mode === 'large'" :class="$style['bpm-timer__inner-text']">
        {{ text }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BpmTimer',
  props: {
    mode: {
      type: String,
      default: 'small' // small, large
    },
    text: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: null // x-small, small, large
    }
  }
}
</script>

<style lang="scss" module src="./styles/BpmTimer.module.scss">
</style>
