<template>
  <div :class="$style['web-mb-tooltip']">
    <slot></slot>
    <!-- v-html -->
    <span :class="$style['web-mb-tooltip__text']">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'TooltipComponent',
  props: {
    text: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-tooltip {
  position: relative;

  &__text {
    visibility: hidden;
    width: 250px;
    background-color: #626C77;
    color: #fff;
    text-align: center;
    border-radius: 0.5rem;
    padding: 0.75rem;
    font-size: 0.75rem;
    position: absolute;
    z-index: 1;
    top: 150%;
    left: 50%;
    margin-left: -125px;
    transition: visibility .15s ease-in-out;

    &::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #626C77 transparent;
    }
  }

  &:hover &__text {
    visibility: visible;
  }
}
</style>
