<template>
  <input
    v-model="value"
    :maxlength="maxlength"
    :type="type"
    @input="handleInput"
    @keydown="handleKeydown"
  >
</template>

<script>
export default {
  name: 'BpmWebOtpInputInput',
  props: {
    index: {
      type: Number,
      default: 1
    },
    maxlength: {
      type: Number,
      default: 1
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  data: () => {
    return {
      value: null
    }
  },
  methods: {
    handleInput () {
      this.$emit('input', {
        value: this.value,
        index: this.index
      })
    },
    handleKeydown (e) {
      if (e.key === 'Backspace') {
        this.$emit('clear', {
          value: this.value,
          index: this.index
        })

        this.value = ''
      }
    }
  }
}
</script>
