<template>
  <div :class="[
      $style['web-mb-container'],
      { [$style['web-mb-container--is-wider']]: isWider }
    ]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ContainerComponent',
  props: {
    isWider: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-container {
  @media (min-width: $min-lg) {
    margin: 0 auto;
    max-width: 1216px;

    &--is-wider {
      max-width: 1232px;
    }
  }
}
</style>
