import Vue from 'vue'
import VueRouter from 'vue-router'

import HomePage from '@/components/site/pages/HomePage.vue'
import ProductsPage from '@/components/site/pages/ProductsPage.vue'
// import AboutPage from '@/components/site/pages/AboutPage.vue'
import ReachPage from '@/components/site/pages/ReachPage.vue'

Vue.use(VueRouter)

export const routes = [
  {
    name: 'home',
    title: 'Home',
    path: '/',
    component: HomePage
  },
  {
    name: 'products',
    title: 'Products',
    path: '/products',
    component: ProductsPage
  },
  // {
  //   name: 'about',
  //   title: 'About Us',
  //   path: '/about',
  //   component: AboutPage
  // },
  {
    name: 'reach',
    title: 'Reach Us',
    path: '/reach',
    component: ReachPage
  }
]

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
