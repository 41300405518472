<template>
  <!-- REVIEWS -->
  <SectionComponent is-wider>
    <ContainerComponent is-wider>
      <SectionHeaderComponent is-wider>Why people love VIVA Money</SectionHeaderComponent>
      <VueSlickCarousel
        v-bind="carouselSettings"
      >
        <div
          v-for="review in reviews"
          :key="review.id"
        >
          <div
            :class="$style['web-mb-reviews-section__card']"
          >
            <div :class="$style['web-mb-reviews-section__card-header']">
              <ImgComponent
                :src="review.img"
                :imgStyle="{ width: '64px', height: 'auto' }"
                alt="Avatar"
              />
              <div>
                <div :class="$style['web-mb-reviews-section__card-name']">{{ review.name }}</div>
                <div :class="$style['web-mb-reviews-section__card-country']">{{ review.country }}</div>
              </div>
            </div>
            <div :class="$style['web-mb-reviews-section__card-text']">
              {{ review.text }}
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </ContainerComponent>
  </SectionComponent>
</template>

<script>
import ContainerComponent from '@/components/ui/ContainerComponent.vue'
import SectionComponent from '@/components/ui/SectionComponent.vue'
import SectionHeaderComponent from '@/components/ui/SectionHeaderComponent.vue'
import ImgComponent from '@/components/ui/ImgComponent.vue'

import VueSlickCarousel from 'vue-slick-carousel'

import avatar1 from '@/assets/images/reviews/avatar-img-1.png'
import avatar2 from '@/assets/images/reviews/avatar-img-2.png'
import avatar3 from '@/assets/images/reviews/avatar-img-3.png'
import avatar4 from '@/assets/images/reviews/avatar-img-4.png'
import avatar5 from '@/assets/images/reviews/avatar-img-5.png'
import avatar6 from '@/assets/images/reviews/avatar-img-6.png'
import avatar7 from '@/assets/images/reviews/avatar-img-7.png'

export default {
  name: 'ReviewsSection',
  components: {
    ContainerComponent,
    SectionComponent,
    SectionHeaderComponent,
    ImgComponent,
    VueSlickCarousel
  },
  data: () => {
    return {
      // json
      reviews: [
        {
          id: 1,
          name: 'Tanmay Dwivedi',
          country: 'India',
          text: '“I think it took me less than 15 minutes to get my account and start withdrawing money. With a vacation coming up, that’s pretty much all I needed — a personal line of credit loan app that let me withdraw as much as required and allow me to repay at my own pace, without worrying about the hidden terms and conditions!”',
          img: avatar1
        },
        {
          id: 2,
          name: 'Somvir Yogi',
          country: 'India',
          text: '″The Grace Period feature of VIVA acts as a helping hand for me as it gives me a free hand to spend as such as required in an emergency situation and I get enough time of up to 51 days to pay it back with zero interest. Interest Free Loan, Big relief!″',
          img: avatar2
        },
        {
          id: 3,
          name: 'Samson Pinto',
          country: 'India',
          text: '″Go to a bank and they’ll ask you a million questions. It was different with Viva. No paperwork, No questions asked and within minutes you get access to your personal line of credit. It was way less stressful than my previous experiences!″',
          img: avatar3
        },
        {
          id: 4,
          name: 'Shwetha Shetty',
          country: 'India',
          text: '“First of all, I love the VIVA instant loan app itself. It’s so clean and minimal. Especially the user-friendly interface and how it doesn’t interrupt my day. I showed it to my dad and he understood the idea of a credit line app so fast. When it’s built to serve you simply, it takes so much stress out of the process.”',
          img: avatar4
        },
        {
          id: 5,
          name: 'Shanu Bhardwaj',
          country: 'India',
          text: '″It’s almost like borrowing money from your parents, you know? It’s fast and you can really count on them. Either it’s a small amount or a big amount. The one benefit Viva’s instant loan app has, though? It doesn’t ask you questions and doesn’t judge you. I’ve taken out money for birthdays, pets, emergencies & nearly for everything.″',
          img: avatar5
        },
        {
          id: 6,
          name: 'Purvi Jain',
          country: 'India',
          text: '″When I read “instant bank transfer”, I was pretty skeptical because who does that? Well, before I knew it, I had money in my account. Now I know it’s an instant loan app I can always count on.″',
          img: avatar6
        },
        {
          id: 7,
          name: 'Charan Kumar',
          country: 'India',
          text: '″The only reason I feared to take a personal loan was of going through tons of documents and a long waiting time. But with Viva’s personal line of credit, within 15 minutes and with no physical documents my credit line got approved and the amount got transferred instantly to my bank account.″',
          img: avatar7
        }
      ],
      carouselSettings: {
        'dots': true,
        'infinite': false,
        'speed': 500,
        'slidesToShow': 3,
        'slidesToScroll': 3,
        'initialSlide': 0,
        'responsive': [
          {
            'breakpoint': 1024,
            'settings': {
              'slidesToShow': 2,
              'slidesToScroll': 2,
              'infinite': true,
              'dots': true
            }
          },
          {
            'breakpoint': 768,
            'settings': {
              'slidesToShow': 1,
              'slidesToScroll': 1,
              // 'initialSlide': 2
            }
          },
          // {
          //   'breakpoint': 480,
          //   'settings': {
          //     'slidesToShow': 1,
          //     'slidesToScroll': 1
          //   }
          // }
        ]
      }
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-reviews-section {
  &__card {
    background-color: #FFFFFF;
    border-radius: 1.5rem;
    padding: 1.5rem 1rem;
    margin: 0 0.5rem;

    &-header {
      display: flex;
      gap: 1.5rem;
      align-items: center;
      padding-bottom: 1.5rem;
    }

    &-name {
      font-weight: 500;
    }

    &-country {
      color: #A0A0A0;
    }

    &-text {
      font-size: 0.875em;
    }
  }

  @media (min-width: $min-md) {
    &__card {
      padding: 2rem 2rem;
    }
  }
}
</style>
