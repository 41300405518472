var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{class:_vm.$style['web-mb-home-page']},[_c('section',{class:_vm.$style['web-mb-home-page__section']},[_c('PagePanelComponent',{class:_vm.$style['web-mb-home-page__panel']},[_c('ContainerComponent',[_c('div',{class:_vm.$style['web-mb-home-page__row']},[_c('div',{class:_vm.$style['web-mb-home-page__col']},[_c('h1',[_vm._v("India’s 1st Personal Line of Credit")]),_c('div',{class:_vm.$style['web-mb-home-page__lists-wrap']},[_c('ul',{class:_vm.$style['web-mb-home-page__list']},[_c('li',[_vm._v("Credit Limit up to ₹2,00,000")]),_c('li',[_vm._v("Revolving line of credit")]),_c('li',[_vm._v("0% interest for up to 51 days")])]),_c('ul',{class:_vm.$style['web-mb-home-page__list']},[_c('li',[_vm._v("Instant online approval in 15 minutes")]),_c('li',[_vm._v("Direct bank transfer")]),_c('li',[_vm._v("Pay interest only on the amount withdrawn")])])]),_c('div',{class:[
                _vm.$style['web-mb-home-page__row'],
                _vm.$style['web-mb-home-page__download']
              ]},[_c('div',{class:_vm.$style['web-mb-home-page__col']},[_c('h2',[_vm._v("Download mobile app")]),_c('div',{class:_vm.$style['web-mb-home-page__download-wrap']},[_c('div',{class:_vm.$style['web-mb-home-page__qr-code-wrap']},[_c('ImgComponent',{attrs:{"src":_vm.qrCodeImg,"alt":"QR Code"}})],1),_c('div',{class:_vm.$style['web-mb-home-page__download-links-wrap']},[_c('ImgComponent',{attrs:{"src":_vm.appStoreImg,"alt":"App Store"}}),_c('ImgComponent',{attrs:{"src":_vm.googlePlayImg,"alt":"Google Play"}})],1)])])])]),_c('div',{class:[
              _vm.$style['web-mb-home-page__col'],
              _vm.$style['web-mb-home-page__img-wrap']
            ]})])])],1)],1),_c('AdvantagesSection',{class:_vm.$style['web-mb-home-page__advantages']}),_c('WebMobBank'),_c('SlidesSection'),_c('ReviewsSection'),_c('BannerSection'),_c('FaqSection')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }