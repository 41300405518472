<template>
  <div
    :class="[
      $style['bpm-textarea'],
      { [$style[`bpm-textarea--${size}`]]: size !== null }
    ]"
  >
    <div :class="$style['bpm-textarea__wrap']">
      <textarea
        :class="classes"
        :disabled="disabled"
        :id="id"
        :maxlength="maxlength"
        :minlength="minlength"
        :name="name"
        :readonly="readonly"
        :required="required"
        :style="style"
        :value="value"
        @input="handleInput"
      ></textarea>
      <label
        :class="$style['bpm-textarea__label']"
        :for="id"
      >
        <div
          :class="$style['bpm-textarea__label-text']"
        ><span v-html="label"></span><span v-if="required" :class="$style['bpm-textarea__label-required']">*</span>
        </div>
      </label>
      <div :class="$style['bpm-textarea__btn']">
        <button
          :class="[
            $style['bpm-textarea__btn-clear'],
            { [this.$style['bpm-textarea__btn-clear--invalid']]: this.state === false }
          ]"
          @click="clearInput"
        ></button>
        <button v-if="state" :class="$style['bpm-textarea__btn-success']"></button>
      </div>
    </div>
    <span
      v-if="error && error !== ''"
      :class="$style['bpm-textarea__error']"
    >{{ error }}</span>
    <span
      v-if="description && description !== ''"
      :class="$style['bpm-textarea__description']"
    >{{ description }}</span>
  </div>
</template>

<script>
import uid from '../../utils/uid'

export default {
  name: 'BpmTextarea',
  props: {
    backgroundColor: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    maxlength: {
      type: String,
      default: null
    },
    minlength: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null // x-small, small, large
    },
    state: {
      type: Boolean, // null, true, false
      default: null
    },
    value: {
      type: String,
      default: null
    }
  },
  computed: {
    classes () {
      return [
        this.$style['bpm-textarea__textarea'],
        { [this.$style['bpm-textarea__textarea--disabled']]: this.disabled },
        { [this.$style['bpm-textarea__textarea--invalid']]: this.state === false },
        { [this.$style['bpm-textarea__textarea--is-full']]: this.value && this.value !== '' },
        { [this.$style[`bpm-textarea__textarea--${this.size}`]]: this.size !== null },
        { [this.$style[`bpm-textarea__textarea--${this.size}`]]: this.size !== null && this.size === 'x-small' },
        { [this.$style['bpm-textarea__textarea--valid']]: this.state === true }
      ]
    },
    id () {
      return uid()
    },
    style () {
      return {
        color: this.color,
        backgroundColor: this.backgroundColor,
        borderColor: this.state === null && this.backgroundColor
      }
    }
  },
  methods: {
    clearInput() {
      this.$emit('input', '')
    },
    handleClick () {
      this.$emit('click')
    },
    handleInput (e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>

<style lang="scss" module src="./styles/BpmTextarea.module.scss">
</style>
