<template>
  <SectionComponent is-wider>
    <ContainerComponent is-wider>
      <VueSlickCarousel
        v-bind="carouselSettings"
      >
        <div
          v-for="advantage in advantages"
          :key="advantage.id"
        >
          <div
            :class="$style['web-mb-advantages-section__card']"
          >
            <div :class="$style['web-mb-advantages-section__card-header']">
              <ImgComponent
                :src="advantage.img"
                :imgStyle="{ width: '40px', height: 'auto' }"
                alt="advantage"
              />
              <div>
                <div :class="$style['web-mb-advantages-section__card-title']" v-html="advantage.title"></div>
              </div>
            </div>
            <div :class="$style['web-mb-advantages-section__card-text']" v-html="advantage.text">
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </ContainerComponent>
  </SectionComponent>
</template>

<script>
import ContainerComponent from '@/components/ui/ContainerComponent.vue'
import SectionComponent from '@/components/ui/SectionComponent.vue'
import ImgComponent from '@/components/ui/ImgComponent.vue'

import VueSlickCarousel from 'vue-slick-carousel'

import img1 from '@/assets/images/advantages/advantage-1-img.svg'
import img2 from '@/assets/images/advantages/advantage-2-img.svg'
import img3 from '@/assets/images/advantages/advantage-3-img.svg'
import img4 from '@/assets/images/advantages/advantage-4-img.svg'

export default {
  name: 'AdvantagesSection',
  components: {
    ContainerComponent,
    SectionComponent,
    ImgComponent,
    VueSlickCarousel
  },
  data: () => {
    return {
      // json
      advantages: [
        {
          id: 1,
          title: 'Revolving line of credit',
          text: 'Withdraw any amount, any time. Your credit limit gets restored each time you pay it back!',
          img: img1
        },
        {
          id: 2,
          title: 'Flexible EMI options',
          text: 'Return the money within Grace period without paying interest or in monthly installments 5, 10 or 20 months',
          img: img2
        },
        {
          id: 3,
          title: 'Lightning fast approvals',
          text: 'Get a decision in 15 minutes without visiting branches and signing paper documents',
          img: img3
        },
        {
          id: 4,
          title: 'Zero<br>Paper',
          text: 'No paperwork or physical documentation is required, and you can apply and get a personal loan completely online.',
          img: img4
        }
      ],
      carouselSettings: {
        'dots': true,
        'infinite': false,
        'speed': 500,
        'slidesToShow': 4,
        'slidesToScroll': 4,
        'initialSlide': 0,
        'responsive': [
          {
            'breakpoint': 1024,
            'settings': {
              'slidesToShow': 2,
              'slidesToScroll': 2,
              'infinite': true,
              'dots': true
            }
          },
          {
            'breakpoint': 768,
            'settings': {
              'slidesToShow': 1,
              'slidesToScroll': 1,
              // 'initialSlide': 2
            }
          },
          // {
          //   'breakpoint': 480,
          //   'settings': {
          //     'slidesToShow': 1,
          //     'slidesToScroll': 1
          //   }
          // }
        ]
      }
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-advantages-section {
  &__card {
    background-color: #FFFFFF;
    border-radius: 1rem;
    padding: 2rem 1.5rem;
    margin: 0 0.5rem;
    border: 1px solid #F5F7FB;
    flex: 1;

    &-header {
      display: flex;
      gap: 1.5rem;
      align-items: center;
      padding-bottom: 1.5rem;
    }

    &-title {
      font-size: 1.111em;
      font-weight: 600;
    }

    &-text {
      font-size: 0.875em;
    }
  }

  @media (min-width: $min-md) {
    &__card {
      padding: 2rem 2rem;
    }
  }
}
</style>
