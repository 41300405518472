var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SectionComponent',{attrs:{"id":"faq"}},[_c('ContainerComponent',[_c('SectionHeaderComponent',[_vm._v("Frequently asked questions")]),_c('div',{class:_vm.$style['web-mb-faq-section__expansion-panels']},[_vm._l((_vm.faq),function(item){return _c('div',{key:item.id,class:_vm.$style['web-mb-faq-section__expansion-panel'],style:({display: !_vm.showAll && item.id <= 6 ? 'block' : _vm.showAll ? 'block' : 'none'})},[_c('div',{class:[
            _vm.$style['web-mb-faq-section__expansion-panel-header'],
            { [_vm.$style['web-mb-faq-section__expansion-panel-header--toggle']]: item.show }
          ],on:{"click":function($event){return _vm.toggleItem(item.id)}}},[_c('div',[_vm._v(_vm._s(item.question))]),_c('div',{class:[
              _vm.$style['web-mb-faq-section__expansion-panel-icon'],
              { [_vm.$style['web-mb-faq-section__expansion-panel-icon--toggle']]: item.show }
            ]})]),(item.show)?_c('div',{class:_vm.$style['web-mb-faq-section__expansion-panel-content'],domProps:{"innerHTML":_vm._s(item.answer)}}):_vm._e()])}),_c('div',{class:_vm.$style['web-mb-faq-section__expansion-panels-toggle'],on:{"click":_vm.handleClick}},[_vm._v("Show "+_vm._s(_vm.showAll ? 'less' : 'more'))])],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }