<template>
  <div
    :class="[
      $style['web-mb-modal'],
      { [$style['web-mb-modal--show']]: showModal }
    ]"
  >
    <div :class="$style['web-mb-modal__wrap']">
      <ContainerComponent>
        <div :class="$style['web-mb-modal__panel']">
          <div :class="$style['web-mb-modal__header']">
            <h3 :class="$style['web-mb-modal__title']">{{ title }}</h3>
            <div
              :class="$style['web-mb-modal__close']"
              @click="$emit('close')"
            ></div>
          </div>
          <div :class="$style['web-mb-modal__content']">
            <slot></slot>
          </div>
        </div>
      </ContainerComponent>
    </div>
  </div>
</template>

<script>
import ContainerComponent from '@/components/ui/ContainerComponent.vue'

// showModal: true - body's overflow: hidden

export default {
  name: 'ModalComponent',
  components: {
    ContainerComponent
  },
  props: {
    title: {
      type: String,
      default: null
    },
    showModal: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-modal {
  display: none;
  position: fixed;
  z-index: 1;
  // padding-top: 100px; // ?
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  padding-top: 1rem;
  padding-bottom: 1rem;

  &--show {
    display: block;
  }

  &__wrap {
    margin: 0 $section-x-indent;
  }

  &__panel {
    // position: fixed;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;
    background-color: #FFFFFF;
    margin: auto;
    padding: 1rem 1.5rem;
    border-radius: 1rem;
  }

  &__close {
    display: block;
    width: 2.75rem;
    height: 1.75rem;
    background-image: url(../../../assets/icons/close-modal-icon.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    cursor: pointer;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__content {
    margin-top: 2rem;
  }

  @media (min-width: $min-md) {
    &__wrap {
      margin: 0 $section-x-indent-md;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    &__panel {
      border-radius: 1.5rem;
      padding: 1.5rem 1.5rem;
    }
  }

  @media (min-width: $min-lg) {
    &__wrap {
      margin: 0 $section-x-indent-lg;
    }
  }
}
</style>
