<template>
  <div :class="$style['web-mb-banner-panel']">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BannerPanelComponent'
}
</script>

<style lang="scss" module>
.web-mb-banner-panel {
  background: linear-gradient(31.01deg, #A92DD2 -42.78%, #A92DD2 7.55%, #9747FF 22.93%, #339C89 70.91%, #43B02A 105.02%);
  color: #FFFFFF;
  border-radius: 1.5rem;

  @media (min-width: $min-md) {
    border-radius: 2rem;
  }

  @media (min-width: $min-lg) {
    border-radius: 2.5rem;
  }
}
</style>
