// рефактор тут

const formatNodeFields = (fields) => {
  const list = {}
  
  fields.forEach((item) => {
    const inlineStyle = {
      'text-align': item?.properties?.align,
      'display': !item?.properties?.visibility ? 'none' : null,
    }

    let listData = [];

    if ((item?.nodeType === 'radiobutton' || item?.nodeType === 'input.dropDownList' || item?.nodeType === 'block.bullet') && isJsonString(item?.properties?.data)) {
      const arrData = JSON.parse(item?.properties?.data)

      const regex = />(.*?)</g

      // loop through the array of strings
      for (let str of arrData) {
        // use the match method to get an array of matches for the regex
        let matches = str.match(regex);
        // if there are matches, push the first match (without the > and <) to the listData array
        if (matches) {
          listData.push(matches[0].slice(1, -1));
        }
      }

      console.log(listData)
    }
    
    list[item?.name] = {
      inlineStyle,
      listData,
      ...item,
    }
  })
  
  return list
}

function isJsonString(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export default formatNodeFields
