<template>
  <div :class="[
      $style['web-mb-section'],
      { [$style['web-mb-section--is-wider']]: isWider }
    ]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SectionComponent',
  props: {
    isWider: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" module>
.web-mb-section {
  margin: $section-y-indent $section-x-indent;

  &--is-wider {
    margin-right: calc(#{$section-x-indent} - 0.5rem);
    margin-left: calc(#{$section-x-indent} - 0.5rem);
  }

  @media (min-width: $min-md) {
    margin: $section-y-indent-md $section-x-indent-md;

    &--is-wider {
      margin-right: calc(#{$section-x-indent-md} - 0.5rem);
      margin-left: calc(#{$section-x-indent-md} - 0.5rem);
    }
  }

  @media (min-width: $min-lg) {
    margin: $section-y-indent-lg $section-x-indent-lg;

    &--is-wider {
      margin-right: calc(#{$section-x-indent-lg} - 0.5rem);
      margin-left: calc(#{$section-x-indent-lg} - 0.5rem);
    }
  }
}
</style>
