<template>
  <label :class="classes" :for="id">{{ label }}{{ required ? '*' : '' }}<slot></slot></label>
</template>

<script>
export default {
  name: 'BpmLabel',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null // x-small, small, large
    }
  },
  computed: {
    classes () {
      return [
        this.$style['bpm-label'],
        { [this.$style[`bpm-label--${this.size}`]]: this.size !== null },
        { [this.$style['bpm-label--disabled']]: this.disabled }
      ]
    }
  }
}
</script>

<style lang="scss" module src="./styles/BpmLabel.module.scss">
</style>
